import Carousel from 'nuka-carousel'
import { INews } from '../../entities'
import { ClipLoader } from 'react-spinners'
import { WrapperNews, NewsItem } from './styles'

type Props = {
  newsList: INews[]
  isLoading: boolean
  border?: boolean
}
const NewsListContent = ({ isLoading, newsList, border = false }: Props) => {
  return (
    <WrapperNews $border={border}>
      {isLoading && (
        <div className="list-loading">
          <ClipLoader color="#002356" size={45} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      )}
      {!isLoading && newsList.length === 0 && (
        <div className="list-empty-message">
          <p>Nenhum destaque encontrado!</p>
        </div>
      )}
      {!isLoading && newsList.length > 0 && (
        <Carousel
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          autoplay={true}
          autoplayInterval={10000}
          wrapAround={true}
        >
          {newsList.map((item) => (
            <NewsItem key={item.id}>
              {item.imageUrlData ? (
                <img
                  src={item.imageUrlData}
                  alt="preview da imagem"
                  className="news-item__img"
                  loading="lazy"
                />
              ) : (
                <span>Imagem não disponível</span>
              )}
              <div className="text" dangerouslySetInnerHTML={{ __html: item.description }}></div>
            </NewsItem>
          ))}
        </Carousel>
      )}
    </WrapperNews>
  )
}

export default NewsListContent

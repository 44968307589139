import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const ContainerHome = styled.div`
  width: 100%;
  height: 80%;
  margin-top: -3.5rem;
  margin-bottom: 0.7rem;

  .home-info {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding-right: 2rem;
    padding-left: 3rem;
    gap: 1.5rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    margin-top: -3.25rem !important;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, 641)} {
    margin-top: -3rem !important;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    margin-top: -2.5rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    margin-top: -3rem;
  }

  ${breakQueriesMaxMin(1320, SizeBreakpoint.xl)} {
    margin-top: -3.15rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    .home-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.xsm)} {
    .home-info {
      margin-bottom: 0.5rem;
    }
  }

  @media (max-height: 768px) {
    /* margin-top: -3.35rem !important; */
    margin-top: -3.65rem !important;
  }
`

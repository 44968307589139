/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAllAttributes } from '../services/api'
import { AttributeModel } from '../entities'
import { OptionSelect } from 'components/Select/Select'

export const useGetAttributes = () => {
  const [isLoadingAttributes, setIsLoadingAttributes] = useState(false)
  const [attributes, setAttributes] = useState<AttributeModel[] | []>([])
  const [attributeOptions, setAttributeOptions] = useState<OptionSelect[] | []>([])
  const [attributeOptionsAll, setAttributeOptionsAll] = useState<OptionSelect[] | []>([])

  async function getAttributeList() {
    setIsLoadingAttributes(true)
    try {
      const response = await getAllAttributes()
      if (!response) {
        throw new Error()
      }
      if (response.data.length > 0) {
        // const _attributesWithoutCategory = filterAttributesWithoutCategories(response)
        // const _attributesWithoutOptions = filterAttributesWithoutOptions(response)
        const _attributeFilteredByType = filterAttributesByType(response.data)
        // GET "Esse chamado é pra vc mesmo" attribute
        const userAtt = response.data.filter((attribute) => attribute.type === 'user')

        const _attributeOptions = formattedAttributesOptions([
          ...userAtt,
          ..._attributeFilteredByType
          // ..._attributesWithoutOptions,
          // ..._attributesWithoutCategory
        ])

        const _attributeOptionsAll = formattedAttributesOptions([...response.data])

        setAttributes(_attributeFilteredByType)
        setAttributeOptions(_attributeOptions)
        setAttributeOptionsAll(_attributeOptionsAll)
      }
      // setAttributes([])
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um error ao buscar os atributos.')
    } finally {
      setIsLoadingAttributes(false)
    }
  }

  useEffect(() => {
    getAttributeList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoadingAttributes,
    attributes,
    attributeOptions,
    attributeOptionsAll,
    getAttributeList
  }
}

// const filterAttributesWithoutCategories = (list: AttributeModel[]) => {
//   return list.filter((attribute) => attribute.optionsSelectElement[0]?.categoryId === null)
// }
// const filterAttributesWithoutOptions = (list: AttributeModel[]) => {
//   return list.filter((attribute) => attribute.optionsSelectElement.length === 0)
// }

const formattedAttributesOptions = (list: AttributeModel[]) => {
  const _attributeOptions: OptionSelect[] = []
  list.forEach((att) => {
    _attributeOptions.push({
      label: att.question,
      value: { ...att }
    })
  })
  return _attributeOptions
}

export const filterAttributesByType = (list: AttributeModel[]): AttributeModel[] => {
  const filter1 = list.filter((attribute) => attribute.type !== 'number')
  const filter2 = filter1.filter((attribute) => attribute.type !== 'table')
  const filter3 = filter2.filter((attribute) => attribute.type !== 'user')
  const filter4 = filter3.filter((attribute) => attribute.type !== 'search')
  // const filter5 = filter4.filter((attribute) => attribute.type !== 'select')
  // const filter6 = filter5.filter((attribute) => attribute.type !== 'checkbox')

  return filter4
}

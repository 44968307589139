import { useGetCardList } from 'hooks'
import {
  CardLits,
  Carousel,
  Contacts,
  NewsCarousel,
  PageModel,
  TicketList
} from '../../components'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

import { ContainerHome } from './styles'

export function Home () {
  const { width } = useWindowDimensions()
  const { cardList, isLoadingCardList } = useGetCardList()

  return (
    <PageModel title="Auto Atendimento">
      <ContainerHome>
        {width < 1080 ? <Carousel isOneRow={true} /> : <CardLits isOneRow={true} cardList={cardList} isLoadingCardList={isLoadingCardList} />}

        <section className="home-info">
          <TicketList />
          <NewsCarousel />
          <Contacts />
        </section>
      </ContainerHome>
    </PageModel>
  )
}

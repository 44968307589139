import { useContext, useEffect } from 'react'
import { FaRegUser } from 'react-icons/fa'
import { AiOutlineLock } from 'react-icons/ai'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AuthContext } from '../../contexts/auth'
import { Button } from 'components'

import { Container, WrapperLogin, ErrorMessage } from './styles'
import logoGNC from '../../assets/sebrae-logo.png'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { InteractionStatus } from '@azure/msal-browser'
import { ClipLoader } from 'react-spinners'

interface IFormInput {
  username: string
  password: string
}

export function SignIn () {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>()
  const navigate = useNavigate()
  const { signIn, isLoading, errorMessage } = useContext(AuthContext)
  const isAuthenticated = useIsAuthenticated()
  const { inProgress, accounts, instance } = useMsal()

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    signIn(data.username, data.password)
  }

  useEffect(() => {
    if (isAuthenticated && accounts[0]) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, inProgress, instance])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem'
      }}
    >
      <ClipLoader color="#002356" size={35} aria-label="Loading Spinner" data-testid="loader" />{' '}
      Carregando
    </div>
    // <Container>
    //   <WrapperLogin>
    //     <img src={logoGNC} alt="logoGNC" className="login-logo" />

  //     <form onSubmit={handleSubmit(onSubmit)} className="login-form">
  //       <div className="form-field">
  //         <div className="wrapper-input-email">
  //           {<FaRegUser className="user-icon" />}
  //           <input
  //             type="text"
  //             placeholder="Digite seu usuário"
  //             {...register('username', { required: true })}
  //           />
  //           <div className="line"></div>
  //           <div className="suffix">@sebraesp.com.br</div>
  //         </div>
  //         {errors.username?.type === 'required' && (
  //           <ErrorMessage>* Campo obrigatório</ErrorMessage>
  //         )}
  //       </div>
  //       <div className="form-field">
  //         {<AiOutlineLock />}
  //         <input
  //           type="password"
  //           placeholder="Digite sua senha"
  //           {...register('password', { required: true })}
  //         />
  //         {errors.password?.type === 'required' && (
  //           <ErrorMessage>* Campo obrigatório</ErrorMessage>
  //         )}
  //       </div>
  //       <div className="login-error">{errorMessage ? <span>{errorMessage}</span> : null}</div>
  //       <Button
  //         type="submit"
  //         text="Entrar"
  //         isLoading={isLoading}
  //         style={{
  //           width: '100%',
  //           height: '2rem',
  //           fontSize: '0.75rem',
  //           marginTop: '-1rem',
  //           background: '#0372ad'
  //         }}
  //       />
  //     </form>
  //   </WrapperLogin>
  // </Container>
  )
}

import { ChangeEvent, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition, IconName, IconProp, findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useClickOutside } from 'hooks'
import { defaultIcons, freeIconsPrefix } from './icons'

import { BtnGroup, IconItem, IconList, IconListWrap, IconWrapper } from './styles'

interface IconPickerProps {
  iconList?: IconProp[]
  onChange: (icon: string) => void
  defaultIcon?: IconProp
  handleError: (error: string | null) => void
}

const IconPicker = ({
  iconList = defaultIcons,
  onChange,
  defaultIcon = '' as IconProp,
  handleError
}: IconPickerProps) => {
  const [icon, setIcon] = useState<IconProp>(defaultIcon)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside({ ref, setIsOpenMenu })
  const defaultValueIcon = 'fa-solid fa-gear' as IconProp

  const handleIconText = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const iconLookup = e.target.value.split(' ')
      const iconName = iconLookup[1]?.replace('fa-', '') as IconName
      const iconPrefix = freeIconsPrefix.find((el) => el.value === iconLookup[0])
      let icon: IconDefinition = null as unknown as IconDefinition

      if (!iconPrefix) {
        handleError('* Nomenclatura do icone errada (Formatos: fa-solid, fa-regular, fa-brands)')
        return
      }
      if (iconName) {
        icon = findIconDefinition({ prefix: iconPrefix.prefix, iconName })
      }
      if (!icon) {
        handleError('* O icone não existe!')
        return
      }
      setIcon(`${iconLookup[0]} ${iconLookup[1]}` as IconProp)
      onChange(`${iconLookup[0]} ${iconLookup[1]}`)
      handleError(null)
    }
  }

  return (
    <IconWrapper ref={ref}>
      <BtnGroup>
        <span className={icon ? 'btn-icon-info' : 'btn-icon-info btn-icon-info-default'}>
          <FontAwesomeIcon icon={icon || defaultValueIcon } />
          <input
            type="text"
            className={icon ? 'btn-info-text' : ' btn-info-text btn-info-text-default'}
            placeholder={icon as string || `Exemplo: ${defaultValueIcon as string}`}
            onBlur={(e) => {
              handleIconText(e)
            }}
          />
        </span>
        <button
          className="btn-menu"
          type="button"
          onClick={() => {
            setIsOpenMenu(!isOpenMenu)
          }}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      </BtnGroup>
      {isOpenMenu && iconList.length > 0 && (
        <IconListWrap>
          <IconList>
            {iconList.map((icon, index) => {
              return (
                <IconItem key={index}>
                  <button
                    type='button'
                    onClick={() => {
                      handleError(null)
                      setIcon(icon)
                      onChange(icon as string)
                    }}
                  >
                    <FontAwesomeIcon icon={icon} />
                  </button>
                </IconItem>
              )
            })}
          </IconList>
        </IconListWrap>
      )}
    </IconWrapper>
  )
}

export default IconPicker

import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AiOutlineMenu } from 'react-icons/ai'
import { LinkProps } from '.'

import { ContainerHamburgerMenu } from './styles'

type Props = {
  items: LinkProps[]
}

const HamburgerMenu = ({ items }: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const { pathname } = useLocation()

  return (
    <ContainerHamburgerMenu>
      <button
        className="hamburger-menu-btn"
        onClick={() => {
          setIsOpenMenu(!isOpenMenu)
        }}
      >
        <AiOutlineMenu />
      </button>
      {!isOpenMenu && null}
      {isOpenMenu && (
        <div className="hamburger-menu-content">
          {items.map((item) => {
            if (item.title === 'Sair') {
              return (
                <Link
                  key={item.title}
                  to={item.href}
                  onClick={() => {
                    localStorage.clear()
                  }}
                  className={pathname === item.href ? 'item active' : 'item'}
                >
                  {' '}
                  {item.title}{' '}
                </Link>
              )
            }
            return (
              <Link
                key={item.title}
                to={item.href}
                className={pathname === item.href ? 'item active' : 'item'}
              >
                {item.title}
              </Link>
            )
          })}
        </div>
      )}
    </ContainerHamburgerMenu>
  )
}

export default HamburgerMenu

import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from 'styles/Breakpoints'

export const ContainerTicketForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: -4.5rem;

  // Align with the header
  padding-right: 4rem;
  padding-left: 3rem;

  .ticket-form-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-size: 1rem;
  padding: 2rem 5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.blue._800};
  margin-bottom: 2rem;

  h2 {
    width: 100%;
    display: block;
    text-transform: uppercase;
    font-size: 1.25rem;
  }

  .form-inputs {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0;
    gap: 1rem;

    label {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .input-textarea, .input-file {
      width: 100% !important;
      display: flex;
      flex-direction: column;
    }
  }

  .form-button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    margin-top: 1.2rem;
  }
  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    padding: 2rem;
    h2 {
      font-size: 1.15rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    padding: 2rem 3rem;
    h2 {
      font-size: 1.15rem;
    }
  }
`

export const ErrorMessage = styled.span`
  width: 100% !important;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red._500};
  font-size: 0.875rem;
  font-weight: 500;
`

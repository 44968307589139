import styled from 'styled-components'

export const ContainerFooter = styled.footer`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.blue._700} 0% 0% no-repeat padding-box;
  padding: 0.5rem 12px;
  
  p {
    text-align: center;
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
  }
`

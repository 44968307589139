import { useParams } from 'react-router-dom'
import {
  Breadcrumb,
  PageModel,
  TicketInfo,
  ContainerLoading
} from '../../components'
import { useGetTicketDetails } from '../../hooks/useGetTicketDetails'

import { ContainerDetailsTicket } from './styles'

// eslint-disable-next-line @typescript-eslint/space-before-function-paren
export function TicketDetails() {
  const { ticketId } = useParams()
  const { ticket, isLoadingTicket } = useGetTicketDetails(ticketId as string)

  return (
    <PageModel title="Detalhes do Chamado">
      <ContainerDetailsTicket>
        <Breadcrumb titlePage="VISUALIZAR SOLICITAÇÃO" goToHome={true} />
        <div className="details-ticket-content">
          {isLoadingTicket ? <ContainerLoading /> : <TicketInfo ticket={ticket} />}
        </div>
      </ContainerDetailsTicket>
    </PageModel>
  )
}

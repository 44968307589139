import { useContext, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import StarsRating from 'react-star-rate'
import { ThemeContext } from 'styled-components'
import { Button, PageModel } from '../../components'

import { ContainerFeedback, getRatingStyles } from './styles'

export function Feedback () {
  const theme = useContext(ThemeContext)

  const [rating, setRating] = useState(2.5)
  const { ticketId } = useParams()

  return (
    <PageModel title="Pesquisa de Feedback">
      <ContainerFeedback>
        <div className="feedback-content">
          <h2>Feedback para o chamado {ticketId}</h2>
          <div className="feedback-rating">
            <span>
              Por favor, avalie a sua experiência com a plataforma escolhendo
              uma das opções abaixo.
            </span>
            <StarsRating
              value={rating}
              onChange={() => setRating}
              style={getRatingStyles(theme?.colors)}
            />
          </div>

          <div className="feedback-btn">
            <Link to="/">
              <button>Voltar para Home</button>
            </Link>
            <Button type='submit' text="Enviar feedback" />
          </div>
        </div>
      </ContainerFeedback>
    </PageModel>
  )
}

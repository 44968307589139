import { AppRoutes } from './routes'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { GlobalStyle } from './styles/GlobalStyle'
import { ThemeProvider } from 'styled-components'
import { themes } from 'styles/themes'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser'
import { msalConfig } from 'authConfig'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { MsalProvider } from '@azure/msal-react'

const msalInstance = new PublicClientApplication(msalConfig)
async function initializeMsal () {
  // get initialize msalInstance
  await msalInstance.initialize()
  await msalInstance.handleRedirectPromise()
}
initializeMsal()

const activeAccount = msalInstance.getActiveAccount()

if (!activeAccount) {
  // Account selection
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }
}

// set the account
msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const authenticationResult = event.payload as AuthenticationResult
    const account = authenticationResult.account
    msalInstance.setActiveAccount(account)
  }
})

// enable account storage event
msalInstance.enableAccountStorageEvents()

function App () {
  return (
    <ThemeProvider theme={themes}>
      <ToastContainer />
      <GlobalStyle />
      <MsalProvider instance={msalInstance}>
        <AppRoutes />
      </MsalProvider>
    </ThemeProvider>
  )
}

export default App
library.add(fab, fas, far)

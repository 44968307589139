import styled from 'styled-components'

export const ContainerConsultOldTicket = styled.div<{ $isLoadingTickets: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content:'flex-start';
  align-items: ${props => props.$isLoadingTickets ? 'center' : 'flex-start'};
  width: 100%;
  height: auto;
  margin-top: -4.5rem;
  margin-bottom: 2rem;


  // Align with the header
  padding-right: 4rem;
  padding-left: 3rem;
`

import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Header, Footer } from '..'

import { Container } from './styles'

type Props = {
  children: ReactNode
  title: string
  isOverflowY?: boolean
}

const Page = ({ children, title, isOverflowY = false }: Props) => {
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <Container $isHomePage={isHomePage} $isOverflowY={isOverflowY}>
      <Header />
      {children}
      <Footer />
    </Container>
  )
}

export default Page

import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Spinner } from 'components'
import { AttributeModel } from '../../entities'
import { getAttributeByQuestion } from 'services/api'
import { ContainerSearchInput, InputSearchList } from 'components/FormAdmin/styles'
import { filterAttributesByType } from 'hooks'

type Props = {
  onDataSelected: (attribute: AttributeModel) => void
  updateData: () => void
}

function SearchAttribute ({ onDataSelected, updateData }: Props) {
  const searchRef = useRef<HTMLDivElement>(null)
  const [dataSelected, setDataSelected] = useState<string | null>(null)
  const [valueToSearch, setValueToSearch] = useState('')
  const [dataFound, setDataFound] = useState<AttributeModel[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchValue = async (value: string) => {
    try {
      const response = await getAttributeByQuestion(value)

      if (!response) {
        throw new Error()
      }
      const _response = filterAttributesByType(response)
      setDataFound(_response)
    } catch (error) {
      setDataFound([])
      toast.error('Ops 😥, ocorreu um erro ao procurar os formulários.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete') {
      clearData()
    }
  }

  const handleDataSelected = (data: AttributeModel) => {
    setDataFound([])
    setDataSelected(data?.question)
    setValueToSearch('')
    onDataSelected(data)
  }

  const clearData = () => {
    setDataFound([])
    setValueToSearch('')
    setDataSelected(null)
  }

  useEffect(() => {
    function handleClickOutside (event: MouseEvent) {
      if (
        searchRef.current &&
        event.target instanceof HTMLElement &&
        !searchRef.current.contains(event.target)
      ) {
        setDataFound([])
        setValueToSearch('')
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRef])

  useEffect(() => {
    if (valueToSearch === '') {
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    const getData = setTimeout(() => {
      handleSearchValue(valueToSearch)
    }, 3000)

    return () => {
      clearTimeout(getData)
    }
  }, [valueToSearch])

  return (
    <ContainerSearchInput ref={searchRef}>
      <div className="search-content">
        <input
          placeholder="Digite a pergunta"
          value={dataSelected ?? valueToSearch}
          onChange={(e) => {
            setValueToSearch(e.target.value)
          }}
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
        />
        {dataFound.length > 0 && (
          <InputSearchList>
            <ul>
              {dataFound.map((data: AttributeModel) => (
                <li
                  key={data.id}
                  onClick={() => {
                    handleDataSelected(data)
                  }}
                >
                  {data?.question.toUpperCase()}
                </li>
              ))}
            </ul>
          </InputSearchList>
        )}

        {!isLoading && dataFound.length === 0 && valueToSearch && (
          <InputSearchList>
            <div className="input-search-item">Nenhum dado encontrado</div>
          </InputSearchList>
        )}
      </div>

      {isLoading ? (
        <Spinner size={25} className="loading" />
      ) : dataSelected ? (
        <FontAwesomeIcon
          className="clear-icon"
          icon={faXmark}
          onClick={() => {
            clearData()
            updateData()
          }}
        />
      ) : (
        <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} />
      )}
    </ContainerSearchInput>
  )
}

export default SearchAttribute

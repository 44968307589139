import { Link } from 'react-router-dom'
import { ModalContent } from '..'
import { ModalBodyInfo } from 'components/Modal/styles'

/* eslint-disable react/no-unescaped-entities */
type Props = {
  isOpenInfoModal?: boolean
  toggleModal: () => void
}

const InformativeModal = ({ toggleModal }: Props) => {
  return (
    <ModalContent
      title="Como adicionar um novo ícone?"
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
    >
      <ModalBodyInfo>
        <p>
          O portal usa a livraria de ícones&nbsp;
          <Link to={'https://fontawesome.com/icons'} target="_blank" rel="noopener">
            Font Awesome,
          </Link>
          &nbsp;onde existem mais de 2000 ícones disponíveis de forma gratuita, clicando em{' '}
          <Link to={'https://fontawesome.com/search?m=free&o=r'} target="_blank" rel="noopener">
            "free and open-source icons"
          </Link>
          &nbsp;você poderá escolher o ícone que deseja usar, uma vez escolhido o ícone você deverá
          copiar o nome do ícone, sendo ele neste formato:{' '}
          <b>
            <i>fa-regular fa-user</i>
          </b>{' '}
          ou{' '}
          <b>
            <i>fa-solid fa-user</i>
          </b>
          , esse é o texto que você vai usar na caixa de entrada para definir o ícone que não esteja
          na lista de ícones oferecidos.
        </p>
        <p>
          Para mais informação acesse ao site: &nbsp;
          <Link to={'https://fontawesome.com'} target="_blank" rel="noopener">
            https://fontawesome.com
          </Link>
        </p>
      </ModalBodyInfo>
    </ModalContent>
  )
}

export default InformativeModal

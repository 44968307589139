import { GetNOldTickets, OldTicketI, OldTicketModel, TicketFollowups, TicketI, TicketModel, TicketModelCostumer } from 'entities'
import { IDatabaseFilter } from 'hooks/useGetOldTickets'

export const formatTicketListData = (list: GetNOldTickets[]) => {
  const dataFormatted = [] as TicketI[]
  list.forEach((item: GetNOldTickets) => {
    dataFormatted.push({
      ticketID: item.ticket_id ?? 'ID inválido, por favor, entre em contato conosco.',
      title: item.ticket_title ?? 'Chamado sem título',
      date: item.ticket_date ?? 'Data do chamado é desconhecido',
      status: item.ticket_status ?? 'Chamado sem status'
    })
  })
  return dataFormatted
}
export const formatOldTicketListData = (list: GetNOldTickets[]) => {
  const dataFormatted = [] as OldTicketI[]
  list.forEach((item: GetNOldTickets) => {
    dataFormatted.push({
      ticketID: item.ticket_id ?? 'ID inválido, por favor, entre em contato conosco.',
      title: item.ticket_title ?? 'Chamado sem título',
      date: item.ticket_date ?? 'Data do chamado é desconhecido',
      status: item.ticket_status ?? 'Chamado sem status',
      requesterName: item.ticket_requester_name ?? 'Nome do soliciante não informado',
      requesterLogin: item.ticket_requester_login ?? 'Login do solicitante não informado'
    })
  })
  return dataFormatted
}

export const dataFollowup = (data: TicketFollowups) => {
  const dataTicketFollowup = data.all_followups
  const allFollowups = dataTicketFollowup.map((data) => ({
    followup: data.followup,
    date: data.date,
    user: data.user,
    fileId: data.fileId
  }))

  const latestFollowup = data.latest_followup || null
  return {
    all_followups: allFollowups.length > 1 ? allFollowups : null,
    latest_followup: latestFollowup
  }
}

export const formatTicketData = (data: TicketModel) => {
  const categoriesFormatted = data.ticket_category ? data.ticket_category.split('>') : []
  const descriptionFormatted = data.ticket_content.split(/\n/g).map(value => value.trim())
  const responseFollowup = dataFollowup(data.ticket_followup)
  const allFollowups = responseFollowup.all_followups
  const latestFollowup = responseFollowup.latest_followup

  const dataFormatted: TicketModelCostumer = {
    id: data.ticket_id || 'ID inválido, por favor, entre em contato conosco.',
    title: data.ticket_title || 'Chamado sem título',
    date_modify: data.ticket_last_mod || 'Chamado sem data de modificação.',
    date_resolve: data.ticket_time_to_resolve || 'Chamado sem data de resolução.',
    category: categoriesFormatted || 'Chamado sem categoria.',
    status: data.ticket_status || 'Chamado sem status.',
    type: data.ticket_type || 'Chamado sem tipo.',
    description: descriptionFormatted || 'Chamado sem descrição.',
    requester: data.ticket_requester || 'Chamado sem usuário de requisição.',
    last_updater: data.ticket_last_updater || 'Chamado sem usuário de atualização.',
    FollowUp: allFollowups,
    LatestFollowup: latestFollowup,
    entity: data.ticket_entity || 'Chamado sem entidade.',
    urgency: data.ticket_urgency || 'Chamado sem qualquer tipo de urgência.',
    priority: data.ticket_priority || 'Chamado sem prioridade.',
    technician: data.ticket_technician || 'Chamado sem técnico.',
    group: data.ticket_group || 'Sem grupo solucionador.'
  }

  return dataFormatted
}
export const formatOldTicketData = (data: OldTicketModel, base: IDatabaseFilter) => {
  const categoriesFormatted = data.ticket_category ? data.ticket_category.split('>') : []
  const descriptionFormatted = (base === 'outdated' ? data.ticket_content ?? '' : data.ticket_description ?? '').split(/\n/g).map(value => value.trim())

  const responseFollowup = dataFollowup(data.ticket_followup)
  const allFollowups = responseFollowup.all_followups
  const latestFollowup = responseFollowup.latest_followup

  const dataFormatted: TicketModelCostumer = {
    id: data.ticket_id || 'ID inválido, por favor, entre em contato conosco.',
    title: data.ticket_title || 'Chamado sem título',
    date_modify: (base === 'outdated' ? data.ticket_last_mod : data.ticket_date) ?? 'Chamado sem data de modificação.',
    date_resolve: (base === 'outdated' ? data.ticket_time_to_resolve : data.ticket_close_date) ?? 'Chamado sem data de resolução.',
    category: categoriesFormatted || 'Chamado sem categoria.',
    status: data.ticket_status || 'Chamado sem status.',
    type: data.ticket_type || 'Chamado sem tipo.',
    description: descriptionFormatted || 'Chamado sem descrição.',
    requester: data.ticket_requester || 'Chamado sem usuário de requisição.',
    last_updater: data.ticket_last_updater || 'Chamado sem usuário de atualização.',
    FollowUp: allFollowups,
    LatestFollowup: latestFollowup,
    entity: data.ticket_entity || 'Chamado sem entidade.',
    urgency: data.ticket_urgency || 'Chamado sem qualquer tipo de urgência.',
    priority: data.ticket_priority || 'Chamado sem prioridade.',
    technician: data.ticket_technician || 'Chamado sem técnico.',
    group: data.ticket_group || 'Sem grupo solucionador.'
  }

  return dataFormatted
}

/* eslint-disable react-hooks/exhaustive-deps */
import { TicketModelCostumer, TicketDataFollowup } from 'entities'

import AdminModal from 'components/Modal/ModalContent'
import { ToastContainer } from 'react-toastify'
import { WrapperFollowupList } from 'components/TicketDetails/styles'
import FollowupItem from 'components/TicketDetails/FollowupItem'
import OldFollowupItem from './OldFollowupItem'
import { IDatabaseFilter } from 'hooks/useGetOldTickets'

type Props = {
  ticket: TicketModelCostumer | null
  toggleModal: () => void
  base: IDatabaseFilter
}

const OldTicketFollowupModal = ({ ticket, toggleModal, base }: Props) => {
  const handleCloseModal = () => {
    toggleModal()
  }

  return (
    <AdminModal
      title="ACOMPANHAMENTO DO CHAMADO"
      handleCloseModal={handleCloseModal}
      handleSubmit={null}
      isLoading={false}
    >
      <WrapperFollowupList>
        {ticket?.FollowUp?.map((data: TicketDataFollowup, index: number) => (
          <OldFollowupItem base={base} key={`${data.followup}+${index}`} followup={data} />
        ))}
      </WrapperFollowupList>
      <ToastContainer />
    </AdminModal>
  )
}

export default OldTicketFollowupModal

import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'

import { Container } from './styles'
import Footer from 'components/Footer'

type Props = {
  children: ReactNode
  title: string
  isOverflowY?: boolean
  isOneRowCard?: boolean
}

const Layout = ({ children, title, isOverflowY = false, isOneRowCard = false }: Props) => {
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <Container $isHomePage={isHomePage} $isOverflowY={isOverflowY}>
      <Header isOneRowCard={isOneRowCard} />
      {children}
      <Footer />
    </Container>
  )
}

export default Layout

/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getUsersByName } from '../../../services/connector'
import { AttributeModel, UserModel } from '../../../entities'

import { ErrorMessage, FormInputCheckbox, FormInputSearch } from './styles'

type Props = {
  attribute: AttributeModel
  setUserName: (value: string) => void
}

const UserField = ({ attribute, setUserName }: Props) => {
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext()
  const [userList, setUserList] = useState<UserModel[]>([])
  const [userSelected, setUserSelected] = useState<string | null>(null)
  const [userNameTyped, setUserNameTyped] = useState('')
  const [isForOtherUser, setIsForOtherUser] = useState(false)

  const userName = JSON.parse(localStorage.getItem('user') as string).userName

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setIsForOtherUser(e.target.checked)
  }

  const handleSearchUser = async (e: ChangeEvent<HTMLInputElement>) => {
    setUserNameTyped(e.target.value)
    try {
      if (e.target.value === '') {
        setUserSelected(null)
        return
      }
      const response = await getUsersByName(e.target.value)
      if (!response) {
        throw new Error()
      }
      setUserList(response)
    } catch (error) {
      setUserList([])
      toast.error('Ops 😥, ocorreu um erro ao procurar o usuário ')
    }
  }

  const handleUserSelected = (user: UserModel) => {
    clearErrors(attribute.optionsSelectElement[0].attributes[0].id.toString())
    setUserName(user.user_full_name)
    setUserSelected(user.user_full_name)
    setValue(attribute.optionsSelectElement[0].attributes[0].id.toString(), user.user_login)
    setUserList([])
    setUserNameTyped('')
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      setUserSelected(null)
      setUserNameTyped('')
    }
  }

  useEffect(() => {
    if (!userSelected && isForOtherUser) {
      setError(attribute.optionsSelectElement[0].attributes[0].id.toString(), {
        type: 'error',
        message: '* Campo obrigatório'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected, userList, isForOtherUser])

  useEffect(() => {
    if (!isForOtherUser) {
      const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin
      setUserName(userName as string)
      setUserList([])
      setUserSelected(null)
      setUserNameTyped('')
      setValue(attribute.optionsSelectElement[0].attributes[0].id.toString(), userLogin)
      clearErrors(attribute.optionsSelectElement[0].attributes[0].id.toString())
    }
  }, [isForOtherUser])

  return (
    <>
      <FormInputCheckbox>
        <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
        <div className="input-checkbox">
          <input type="checkbox" name={attribute.id.toString()} onChange={handleCheckbox} />
          <p>Não</p>
        </div>
      </FormInputCheckbox>
      {isForOtherUser && (
        <FormInputSearch>
          <div className="input-search">
            <label htmlFor={attribute.optionsSelectElement[0].attributes[0].id.toString()}>
              {attribute.optionsSelectElement[0].attributes[0].question}
            </label>
            <input
              type="search"
              name={attribute.optionsSelectElement[0].attributes[0].id.toString()}
              autoComplete="off"
              placeholder="Escreva o nome completo"
              value={userSelected ?? userNameTyped}
              onChange={(e) => {
                handleSearchUser(e)
              }}
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
            />
          </div>
          {userList.length > 0 && (
            <div className="input-search-list">
              <ul>
                {userList.map((user) => (
                  <li
                    key={user.user_id}
                    onClick={() => {
                      handleUserSelected(user)
                    }}
                  >
                    {user?.user_full_name.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {userList.length === 0 && userNameTyped && (
            <div className="input-search-list">
              <div className="input-search-item">Nenhum usuário encontrado</div>
            </div>
          )}
          {errors[attribute.optionsSelectElement[0].attributes[0].id.toString()] && (
            <ErrorMessage>
              {errors[attribute.optionsSelectElement[0].attributes[0].id.toString()]?.message?.toString()}
            </ErrorMessage>
          )}
        </FormInputSearch>
      )}
    </>
  )
}

export default UserField

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaChevronCircleLeft } from 'react-icons/fa'

import { ContainerBreadcrumb } from './styles'

type Props = {
  titlePage?: string
  goToHome?: boolean
  goBackStep?: boolean
}

const Breadcrumb = ({ titlePage = '', goToHome = false, goBackStep = false }: Props) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    if (goToHome) {
      navigate('/')
      return
    }
    if (goBackStep) {
      navigate(-2)
      return
    }
    navigate(-1)
  }

  return (
    <ContainerBreadcrumb>
      <button onClick={handleGoBack}>
        <FaChevronCircleLeft />
        Voltar
      </button>
      <h4>{titlePage}</h4>
    </ContainerBreadcrumb>
  )
}

export default Breadcrumb

import { ContainerFooter } from './styles'

const Footer = () => {
  return (
    <ContainerFooter>
      <p>SEBRAE - 2024. Todos os direitos reservados. Versão 1.0</p>
    </ContainerFooter>
  )
}

export default Footer

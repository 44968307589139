import { IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core'

type Prefixes = 'fa-solid' | 'fa-regular' | 'fa-brands'
type Props = {
  value: Prefixes
  prefix: IconPrefix
}
export const freeIconsPrefix: Props[] = [
  { value: 'fa-solid', prefix: 'fas' },
  { value: 'fa-regular', prefix: 'far' },
  { value: 'fa-brands', prefix: 'fab' }
]

export const defaultIcons: IconProp[] = [
  'fa-solid fa-camera' as IconProp,
  'fa-solid fa-code-fork' as IconProp,
  'fa-solid fa-key' as IconProp,
  'fa-solid fa-desktop' as IconProp,
  'fa-solid fa-gear' as IconProp,
  'fa-solid fa-laptop' as IconProp,
  'fa-solid fa-mobile-screen-button' as IconProp,
  'fa-solid fa-headset' as IconProp,
  'fa-solid fa-phone' as IconProp,
  'fa-solid fa-sd-card' as IconProp,
  'fa-solid fa-file-circle-plus' as IconProp,
  'fa-solid fa-headphones' as IconProp,
  'fa-solid fa-phone-volume' as IconProp,
  'fa-regular fa-trash-can' as IconProp,
  'fa-solid fa-trash-can' as IconProp,
  'fa-solid fa-database' as IconProp,
  'fa-regular fa-calendar' as IconProp,
  'fa-solid fa-calendar' as IconProp,
  'fa-solid fa-chart-simple' as IconProp,
  'fa-solid fa-link' as IconProp,
  'fa-solid fa-business-time' as IconProp,
  'fa-solid fa-users' as IconProp,
  'fa-regular fa-address-book' as IconProp,
  'fa-solid fa-sliders' as IconProp,
  'fa-solid fa-wifi' as IconProp,
  'fa-solid fa-gears' as IconProp,
  'fa-regular fa-folder' as IconProp,
  'fa-solid fa-folder' as IconProp,
  'fa-solid fa-print' as IconProp,
  'fa-regular fa-circle-user' as IconProp,
  'fa-solid fa-circle-user' as IconProp,
  'fa-solid fa-paperclip' as IconProp,
  'fa-regular fa-envelope' as IconProp,
  'fa-solid fa-envelope' as IconProp,
  'fa-solid fa-download' as IconProp,
  'fa-regular fa-user' as IconProp,
  'fa-solid fa-user' as IconProp,
  'fa-solid fa-house' as IconProp,
  'fa-solid fa-file-contract' as IconProp,
  'fa-solid fa-robot' as IconProp,
  'fa-solid fa-microchip' as IconProp,
  'fa-solid fa-signal' as IconProp,
  'fa-solid fa-chalkboard' as IconProp,
  'fa-brands fa-soundcloud' as IconProp,
  'fa-solid fa-marker' as IconProp,
  'fa-regular fa-file-word' as IconProp,
  'fa-solid fa-file-word' as IconProp,
  'fa-solid fa-calendar-week' as IconProp,
  'fa-solid fa-server' as IconProp,
  'fa-regular fa-address-card' as IconProp,
  'fa-solid fa-address-card' as IconProp,
  'fa-solid fa-shield' as IconProp,
  'fa-solid fa-cash-register' as IconProp,
  'fa-solid fa-network-wired' as IconProp,
  'fa-regular fa-file-excel' as IconProp,
  'fa-solid fa-file-excel' as IconProp,
  'fa-regular fa-building' as IconProp,
  'fa-solid fa-building' as IconProp,
  'fa-solid fa-table' as IconProp,
  'fa-solid fa-copy' as IconProp,
  'fa-solid fa-grip-lines' as IconProp,
  'fa-solid fa-grip' as IconProp,
  'fa-solid fa-gavel' as IconProp,
  'fa-solid fa-folder-tree' as IconProp,
  'fa-solid fa-folder-plus' as IconProp,
  'fa-solid fa-folder-minus' as IconProp,
  'fa-solid fa-folder-closed' as IconProp,
  'fa-solid fa-file-zipper' as IconProp,
  'fa-solid fa-file-video' as IconProp,
  'fa-solid fa-file-shield' as IconProp,
  'fa-solid fa-file-powerpoint' as IconProp,
  'fa-solid fa-file-lines' as IconProp,
  'fa-regular fa-file-lines' as IconProp,
  'fa-solid fa-file-image' as IconProp,
  'fa-solid fa-envelopes-bulk' as IconProp,
  'fa-solid fa-envelope-open-text' as IconProp,
  'fa-solid fa-envelope-open' as IconProp,
  'fa-regular fa-envelope-open' as IconProp,
  'fa-solid fa-envelope-circle-check' as IconProp,
  'fa-solid fa-display' as IconProp,
  'fa-solid fa-computer' as IconProp,
  'fa-solid fa-cloud-arrow-down' as IconProp,
  'fa-solid fa-building-user' as IconProp,
  'fa-brands fa-square-whatsapp' as IconProp,
  'fa-brands fa-expeditedssl' as IconProp,
  'fa-solid fa-chalkboard-user' as IconProp,
  'fa-solid fa-box-archive' as IconProp,
  'fa-regular fa-id-card' as IconProp
]

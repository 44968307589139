import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { EditAdmin } from 'services/api'
import { UserAdminModel } from '../../entities'
import { ModalContent } from 'components'
import { TextField } from 'components/TicketForms/Fields'

import { ModalBodyForm } from 'components/Modal/styles'
interface IFormInput {
  name: string
  login: string
}

type Props = {
  toggleModal: () => void
  adminSelected: UserAdminModel
  getAdmins: () => void
}

const EditAdminModal = ({ toggleModal, adminSelected, getAdmins }: Props) => {
  const defaultValues = {
    name: adminSelected?.name,
    login: adminSelected?.userLogin
  }
  const methods = useForm<IFormInput>({
    defaultValues
  })
  const [isLoading, setIsLoading] = useState(false)
  const user = JSON.parse(localStorage.getItem('user') as string)

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const isSameName = data.name.toLowerCase() === adminSelected?.name.toLowerCase()
      const isSameLogin = data.login.toLowerCase() === adminSelected?.userLogin.toLowerCase()

      if (isSameLogin && isSameName) {
        setIsLoading(false)
        methods.reset()
        toggleModal()
        toast.warn('Nada para editar!')
        return
      }

      const name = data.name || adminSelected?.name
      const login = data.login || adminSelected?.userLogin

      const response = await EditAdmin(name, login, adminSelected?.id as string)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      getAdmins()
    } catch (error: any) {
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao editar os dados do administrador')
    } finally {
      methods.reset({
        name: '',
        login: ''
      })
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (adminSelected) {
      methods.setValue('name', adminSelected.name)
      methods.setValue('login', adminSelected.userLogin)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminSelected])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar administrador"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 'name',
              question: 'Digite o nome:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: adminSelected?.name
            }}
          />
          {user.userLogin !== adminSelected.userLogin && (
            <TextField
              attribute={{
                id: 'login',
                question: 'Digite o login:',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false,
                placeholder: adminSelected?.userLogin
              }}
            />
          )}
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default EditAdminModal

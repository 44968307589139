/* eslint-disable @typescript-eslint/quotes */
import { memo, useState, useRef } from 'react'
import { useGetColorFromTicketStatus } from '../../hooks'
import { TicketModelCostumer } from 'entities'
import TicketFollowupModal from './TicketFollowupModal'

import {
  BottomContent,
  Container,
  Description,
  MiddleContent,
  Information,
  Title,
  TopContent,
  ModalWrapper,
  BtnViewMore
} from './styles'
import { TicketStatus } from '../Home/styles'
import { useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import FollowupItem from './FollowupItem'

type Props = {
  ticket: TicketModelCostumer | null
}

const TicketInfo = ({ ticket }: Props) => {
  const { getColor } = useGetColorFromTicketStatus()
  const navigate = useNavigate()
  const refTicketModal = useRef<HTMLDialogElement>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const noShowBtn =
    ticket?.status.toUpperCase() === 'FECHADO' || ticket?.status.toUpperCase() === 'AGENDADO'
  const label = ticket?.status.toUpperCase() === 'SOLUCIONADO' ? 'REABRIR' : 'ADICIONAR COMENTÁRIO'

  const handleRedirect = () => {
    if (ticket?.id) {
      navigate(`/updateTicket/${ticket?.id}`, {
        state: { status: ticket.status }
      })
    }
  }

  const toggleTicketModal = () => {
    if (refTicketModal.current) {
      if (isOpenModal) {
        setIsOpenModal(false)
        refTicketModal?.current.close()
        return
      }
      setIsOpenModal(true)
      refTicketModal?.current.showModal()
    }
  }

  return (
    <Container>
      <TopContent>
        <Title>
          <div>
            <span className="title">Início&nbsp;</span>
            {ticket?.category.map((category) => (
              <span key={category} className="title">
                ▶&nbsp;{category}&nbsp;
              </span>
            ))}
          </div>
          <div className="id">
            <span>Chamado</span>
            <span>{ticket?.id}</span>
          </div>
        </Title>
        <span className="description">{ticket?.title}</span>
        <div className="ticket-info">
          <Information>
            <div className="status">
              <span>Status:</span>
              {ticket?.status.toUpperCase() === 'PROCESSANDO ATRIBUÍDO' ? (
                <TicketStatus $statusColor={getColor(ticket.status)}>Em Andamento</TicketStatus>
              ) : (
                <TicketStatus $statusColor={getColor(ticket?.status as string)}>
                  {ticket?.status}
                </TicketStatus>
              )}
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Data de abertura:</span>
              <span>{ticket?.date_modify}</span>
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Fila de atendimento</span>
              <span>{ticket?.group.replace(/_.*/, '')}</span>
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Prazo para solução:</span>
              <span>{ticket?.date_resolve}</span>
            </div>
          </Information>
          {!noShowBtn && (
            <button className="ticket-info-btn" onClick={handleRedirect}>
              {label}
            </button>
          )}
        </div>
      </TopContent>
      <MiddleContent>
        <span className="description">DESCRIÇÃO DO CHAMADO</span>
        <Description>
          {ticket?.description.map((description, index) => (
            <span key={`${description}+${index}`} className="response">
              {description}
            </span>
          ))}
        </Description>
      </MiddleContent>
      <BottomContent>
        <div className="comment">
          <span className="accompaniment">ACOMPANHAMENTO DO CHAMADO</span>

          {ticket?.LatestFollowup ? (
            <FollowupItem followup={ticket.LatestFollowup} />
          ) : (
            <span className="text">Este chamado ainda não tem acompanhamento.</span>
          )}

          {ticket?.FollowUp && ticket?.FollowUp?.length > 1 && (
            <BtnViewMore>
              <button onClick={toggleTicketModal}>
                <span>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                Ver mais
              </button>
            </BtnViewMore>
          )}

          <ModalWrapper ref={refTicketModal} $width="70%">
            {isOpenModal && <TicketFollowupModal ticket={ticket} toggleModal={toggleTicketModal} />}
          </ModalWrapper>
        </div>
      </BottomContent>
    </Container>
  )
}

export default memo(TicketInfo)

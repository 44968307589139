import styled from 'styled-components'

export const WrapperDropdownForm = styled.div`
  width: 100%;
  .wrapper-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    .title {
      width: 100%;
      &:first-letter {
        text-transform: uppercase !important;
      }
    }

    button {
      text-align: left;
      margin-top: 0.75rem;
      border: none;
      background-color: transparent;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.blue._600};

      &:hover {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
`

import { useMemo, useState } from 'react'
import ArticleItem from './ArticlesItem'
import { ArticleModel } from '../../entities'

import { ContainerFaq } from './styles'

type Props = {
  articles: ArticleModel[]
  formId: string | number
}

export interface ArticleI extends ArticleModel {
  isOpen: boolean
}

const ArticleList = ({ articles, formId }: Props) => {
  const { list } = useMemo(() => {
    const list: ArticleI[] = []
    articles.map((article) => list.push({ ...article, isOpen: false }))
    return { list }
  }, [articles])

  const [newList, setNewList] = useState<ArticleI[]>(list)

  const onOpenArticle = (articleId: string | number) => {
    const list: ArticleI[] = []
    newList.forEach((article) => {
      if (article.id === articleId) {
        list.push({ ...article, isOpen: !article.isOpen })
      } else {
        list.push({ ...article, isOpen: false })
      }
    })
    setNewList(list)
  }

  return (
    <ContainerFaq>
      {newList?.map((article) => (
        <ArticleItem
          key={article.id}
          article={article}
          onOpenArticle={onOpenArticle}
          formId={formId}
        />
      ))}
    </ContainerFaq>
  )
}

export default ArticleList

import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ContainerLoading, PageModel, TicketListAll } from '../../components'
import { useGetTickets } from '../../hooks'
import { AuthContext } from 'contexts/auth'
import { getTicket } from 'services/connector'
import { TicketI } from '../../entities'

import { ContainerConsultTicket } from './styles'

export const ConsultTicketList = () => {
  const [status, setStatus] = useState('')
  const [ticketById, setTicketById] = useState<TicketI[] | null>(null)
  const [isLoadingTicketById, setLoadingTicketById] = useState(false)
  const listSize = 12
  const [index, setIndex] = useState(listSize)
  const { isLoadingTickets, ticketList } = useGetTickets(index, status)
  const maxLength = 250
  const hasMoreTickets = ticketList?.length <= maxLength && ticketList?.length >= listSize
  const { logout } = useContext(AuthContext)
  const navigate = useNavigate()

  const getTicketDetails = async (ticketId: string): Promise<void> => {
    setLoadingTicketById(true)
    try {
      const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin

      if (!userLogin) {
        toast.error(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
        setTimeout(() => {
          logout()
        }, 3000)
        return
      }

      const response = await getTicket(ticketId)

      if (!response) {
        throw new Error()
      }

      navigate(`/ticket/detail/${ticketId}`)
    } catch (error: any) {
      setLoadingTicketById(false)
      setTicketById([])
      if (error?.response?.data?.err === 'Chamado não encontrado' || error?.response?.data?.err === 'Usuário não é o solitante do chamado') {
        toast.error('Ops 😥, Chamado não encontrado! ')
      } else {
        toast.error('Ops 😥, ocorreu um erro ao recuperar o chamado ')
      }
    }
  }

  const handleSearch = async (value: string) => {
    if (value) {
      await getTicketDetails(value)
    }
  }

  return (
    <PageModel title="Consulta de solicitação">
      <ContainerConsultTicket $isLoadingTickets={isLoadingTicketById || isLoadingTickets}>
        {isLoadingTickets || isLoadingTicketById ? (
          <ContainerLoading />
        ) : (
          <TicketListAll
            ticketList={ticketById ?? ticketList}
            setStatus={setStatus}
            setIndex={setIndex}
            index={index}
            hasMoreTickets={hasMoreTickets}
            listSize={listSize}
            handleSearch={handleSearch}
            setTicketById={setTicketById}
          />
        )}
      </ContainerConsultTicket>
    </PageModel>
  )
}

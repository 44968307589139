import { useFormContext } from 'react-hook-form'
import { AttributeModel, AttributeOptionModel } from '../../../entities'

import { ErrorMessage, FormInputCheckboxMultiSelect } from './styles'

type Props = {
  attribute: AttributeModel
}

const DownloadField = ({ attribute }: Props) => {
  const {
    formState: { errors }
  } = useFormContext()

  return (
    <div>
      <FormInputCheckboxMultiSelect $isMulti={attribute.optionsSelectElement.length > 0}>
        <div className="wrapper-checkbox">
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          <div className="download-list">
            {attribute.optionsSelectElement.map((option: AttributeOptionModel) => (
              <div key={option.id}>
                <div className="link">
                  <a target="_blank" href={option.value} rel="noreferrer">
                    <p>{option.title}</p>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </FormInputCheckboxMultiSelect>
    </div>
  )
}

export default DownloadField

import { heightTicketBox, mbTicketBox } from 'components/Home/styles'
import styled from 'styled-components'
import { breakQueriesMaxMin, SizeBreakpoint } from 'styles/Breakpoints'

export const WrapperNews = styled.div<{ $border?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.875rem;
  border: ${(props) => (props.$border ? '1px solid #002356' : 'none')};
  min-height: calc((${heightTicketBox.default} * 4) + (${mbTicketBox} * 3));
  height: calc(${heightTicketBox.default} * 4);

  .list-empty-message {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue._500};
  }

  // Class from nuka-carousel lib
  .slider-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100% !important;
    padding: 0.75rem 0.75rem;

    .slide {
      display: flex;
      justify-content: center;
    }

    .slider-control-bottomcenter {
      margin-top: auto !important;
      ul {
        top: -0.5rem !important;
      }
      .paging-item {
        margin-left: 0.875rem;
        .paging-dot {
          width: 0.75rem;
          height: 0.75rem;
          opacity: 1;
          border: 1px solid ${({ theme }) => theme.colors.blue._500};
          border-radius: 1.25rem;
          fill: none;
          background-color: ${({ theme }) => theme.colors.blue._500};
        }
      }
    }
  }

  // Class from nuka-carousel lib - end

  ${breakQueriesMaxMin(1100, 910)} {
    .slider-container {
      padding: 0.75rem 0.5rem;
    }
  }

  ${breakQueriesMaxMin(910, 810)} {
    .slider-container {
      padding: 0.75rem 0.5rem;
    }
  }

  ${breakQueriesMaxMin(810, SizeBreakpoint.md)} {
    .slider-container {
      padding: 0.75rem 0.5rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .paging-item {
      margin-left: 0.75rem;
      .paging-dot {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  @media (min-height: 900px) and (max-height: 1080px) and (min-width: 1366px) {
    min-height: calc((${heightTicketBox.lg} * 4) + (${mbTicketBox} * 3));
    height: calc(${heightTicketBox.lg} * 4);
  }
`

export const NewsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 0.5rem;

  @media (min-height: 900px) and (max-height: 1080px) and (min-width: 1366px) {
    .text {
      margin-top: 0.5rem;
    }
  }


  img {
    border-radius: 1.5rem !important;
    width: 90%;
    max-width: 30rem;
    height: auto;
    max-height: 12.5rem;
    object-fit: fill;

    /* @media (min-width: 3840px) {
      object-fit: scale-down;
    } */

    /* ${breakQueriesMaxMin(3840, 1920)} {
      object-fit: scale-down;
    }
    ${breakQueriesMaxMin(SizeBreakpoint.md, 0)} {
      object-fit: scale-down;
    } */
  }


  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90%;
    text-align: left;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue._800};

    p {
      text-align: center !important;
    }
  }
`

import { useState } from 'react'
import { toast } from 'react-toastify'
import { getFormByUuid } from '../services/api'
import { CategoryStatus, CategoryType, FormI, ICategory } from '../entities'

export const useGetForm = () => {
  const [form, setForm] = useState<FormI | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const getForm = async (formId: string) => {
    setIsLoading(true)
    try {
      const response = await getFormByUuid(formId)

      if (!response) {
        throw new Error()
      }
      const labelName = response.ancestorsCardTreeName ? response.ancestorsCardTreeName.split('>')[0] : response.associatedCard?.name

      const category: ICategory | null = response.categoryId ? {
        categoryId: response.categoryId,
        name: response.categoryName as string,
        type: response.type as CategoryType,
        group: response.group as string,
        status: (response.openingStatus as CategoryStatus) ?? 'DIR'
      } : null

      setForm({
        id: response.id,
        title: response.title,
        isEnable: response.isEnable,
        category,
        attributes: response.attributes,
        color: response.associatedCard?.color,
        parentName: labelName,
        articles: response.articles ?? [],
        parentCard: response.associatedCard?.name,
        ancestorsCardTreeName: response?.ancestorsCardTreeName,
        associatedCard: response?.associatedCard
      })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao recuperar o formulário ')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoadingForm: isLoading,
    form,
    getForm
  }
}

import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Breadcrumb, Button, PageModel } from '../../components'
import { FileField, TextareaField } from 'components/TicketForms/Fields'
import { deleteFile, getFileUrl } from 'services/fileManager'
import { updateStatusTicket } from 'services/connector'
import { UpdateTicketToSubmit } from '../../entities'

import { ContainerTicketForm, Form } from './styles'

export const UpdateTicket = () => {
  const methods = useForm()
  const navigate = useNavigate()
  const { ticketId } = useParams()
  const { state } = useLocation()
  const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin
  const [isLoading, setIsLoading] = useState(false)

  const getUrlFile = async (fileData: File) => {
    const formData = new FormData()
    formData.append('files', fileData)
    const response = await getFileUrl(formData)
    if (!response.data) {
      throw new Error()
    }
    return [
      {
        file_url: response.data[0].name,
        file_name: response.data[0].originalname,
        file_token: process.env.REACT_APP_TOKEN_RECIVED_FILE_MANAGER
      }
    ]
  }

  const onSubmitForm = async (data: Record<string, string | File>) => {
    setIsLoading(true)
    let attachmentsGLPI: Array<{ file_url: string, file_token: any, file_name: string }> = []
    if (data.file) {
      attachmentsGLPI = await getUrlFile(data.file as File)
    }
    try {
      const dto: UpdateTicketToSubmit = {
        user_login: userLogin as string,
        content: data.description as string,
        ticket_id: ticketId,
        attachments_glpi: attachmentsGLPI,
        ticket_status: state.status
      }

      const response = await updateStatusTicket(dto)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      navigate(`/ticket/detail/${ticketId as string}`)
    } catch (error) {
      setIsLoading(false)
      if (attachmentsGLPI.length > 0) {
        const urlFile = [{ name: attachmentsGLPI[0]?.file_url }]
        await deleteFile(urlFile)
      }

      toast.error('Ops 😥, ocorreu um erro ao atualizar o chamado')
    }
  }
  return (
    <PageModel title="Atualizar chamado">
      <ContainerTicketForm>
        <Breadcrumb titlePage="Atualizar chamado" />
        <div className="ticket-form-content">
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmitForm)}>
              <h2>Preencha os campos abaixo </h2>
              <div className="form-inputs">
                <TextareaField
                  attribute={{
                    id: 'description',
                    question: 'Qual seu comentário para o chamado?',
                    optionsSelectElement: [],
                    required: true,
                    isHiddenToVip: false,
                    placeholder: ''
                  }}
                />
                <FileField
                  attribute={{
                    id: 'file',
                    question: 'Você deseja anexar arquivo? (docx, pdf, png, jpeg ou xls)',
                    optionsSelectElement: [],
                    required: false,
                    isHiddenToVip: false,
                    placeholder: ''
                  }}
                />
              </div>
              <div className="form-button">
                <Button type="submit" isLoading={isLoading} text="Enviar" />
              </div>
            </Form>
          </FormProvider>
        </div>
      </ContainerTicketForm>
    </PageModel>
  )
}

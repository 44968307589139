import { ICategory } from '../../entities'
import { WrapperCategoryBtn } from './styles'

type Props = {
  categorySelected?: Pick<ICategory, 'name' | 'type' | 'categoryId' | 'group' | 'status'> | null
  toggleCategoryModal: () => void
  hasButton?: boolean
  labelBtn?: string
}

const CategoryInfoButton = ({ categorySelected, toggleCategoryModal, hasButton = true, labelBtn = 'Escolher categoria' }: Props) => {
  return (
    <WrapperCategoryBtn>
      {categorySelected?.categoryId && (
        <div className="category-info">
          <label>Categoria selecionada:</label>
          <p className="name">{categorySelected.name}</p>
          <p className="id">{`ID: ${categorySelected.categoryId} `}</p>
          <p className="type">Tipo: {categorySelected.type === 'R' ? 'Requisição' : 'Incidente'}</p>
          <p className="type">Status: {categorySelected.status === 'DIR' ? 'Em andamento' : 'Em andamento'}</p>
        </div>
      )}
      {hasButton && (
        <button type="button" className="btn-category" onClick={toggleCategoryModal}>
          {labelBtn}
        </button>
      )}
    </WrapperCategoryBtn>
  )
}

export default CategoryInfoButton

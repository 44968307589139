import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../../styles/Breakpoints'

export const FormInputCheckbox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  .input-checkbox {
    display: flex;
    flex-direction: row;
    margin-left: 2.5rem;

    input {
      height: 1.15rem;
      width: 1.15rem;
      max-width: 1.15rem;
      min-width: 1.15rem;
    }

    p {
      margin-left: 0.5rem;
    }
  }
`

export const FormInputSearch = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;

  .input-search {
    width: 100% !important;

    input {
      margin-top: 5px;
      width: 100% !important;
    }
  }

  .input-search__loading {
      display: flex;
      position: relative;

      .loading{
        position: absolute;
        float: right;
        margin-left:auto;
        right: 1%;
      }
  }

  .input-search-list {
    display: block;
    width: 100% !important;
    height: fit-content;
    max-height: 14rem;
    overflow-y: scroll;
    color: ${({ theme }) => theme.colors.gray._500};
    border: 1px solid ${({ theme }) => theme.colors.gray._400};
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: $white;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0rem 1rem;
    background-clip: padding-box;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    ul {
      list-style-type: none;

      li {
        margin-top: 0.5rem;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  // DELETE
  .input-search-item {
    margin-top: 0.5rem;
    ul {
      list-style-type: none;
      li {
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
`

export const FormInputText = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
`

export const FormInputTextarea = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
`

export const FormInputTime = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
`

export const FormInputCPF = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  input {
    max-width: 15rem;
    min-width: fit-content;
  }
`

export const FormInputPhone = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  input {
    max-width: 15rem;
    min-width: fit-content;
  }
`

export const FormFieldSelect = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray._500};

  label {
    color: ${({ theme }) => theme.colors.blue._800};
    font-weight: bold;
  }
`

export const FormFileField = styled.div<{ $isDisable: boolean }>`
  width: 100% !important;
  display: flex;
  flex-direction: column;

  .wrapper-one-file {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }

  .wrapper-multiple-files {
    display: flex;
    flex-direction: column;
  }

  .form-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray._500};
    font-weight: 400;
    line-height: 1.5;
  }

  .form-file-input {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 12rem;

    height: 2.3rem;
    border-radius: 0.3rem;
    background-color: $white;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray._500};
    border: 1px solid ${({ theme }) => theme.colors.gray._400};
    font-weight: 400;
    line-height: 1.5;
    padding: 0rem 1rem;

    cursor: ${(props) => (props.$isDisable ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.$isDisable ? '0.5' : '')};
    svg {
      margin-right: 0.25rem;
    }
  }

  .form-file-input-info {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray._500};
    margin-left: 0.4rem;
    align-self: center;
  }
  .form-file-list {
    display: flex;
    row-gap: 1rem;
    column-gap: 2rem;
    flex-wrap: wrap;
    margin-top: 0.2rem;
  }

  .form-file-list-name {
    color: ${({ theme }) => theme.colors.gray._500};
    width: auto;
    display: flex;
    align-items: center;
    gap: 0.3rem;

    .form-file-list-icon {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        width: 1.1rem;
        height: 1.1rem;
        color: #cf1f3f;
      }
    }
    .form-file-list-icon:hover {
      border: 1px #cf1f3f solid;
      border-radius: 5px;
    }
  }
`

export const FormInputCheckboxMultiSelect = styled.div<{ $isMulti: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  .download-list {
    display: flex;
    width: 100%;
    gap: 2rem;
    margin-top: 0.5rem;
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 30px;
    padding: 0 6px;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.blue._700};
    border: none;
    p {
      color: ${({ theme }) => theme.colors.white};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue._800};
      border-radius: 0.5rem;
    }
  }

  .wrapper-checkbox {
    display: flex;
    flex-direction: ${(props) => (props.$isMulti ? 'column' : 'row')};
  }
  .checkbox-list {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    margin-bottom: 0.5rem;

    .input-checkbox {
      display: flex;
      flex-direction: row;
      margin-left: ${(props) => (props.$isMulti ? '0' : '2.5rem')};
      input {
        height: 1.15rem;
        width: 1.15rem;
        max-width: 1.15rem;
        min-width: 1.15rem;
      }

      p {
        margin-left: 0.75rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    .checkbox-list {
      display: ${(props) => (props.$isMulti ? 'grid' : 'flex')};
      grid-template-columns: repeat(6, 1fr);
      row-gap: 0.5rem;
      column-gap: 2rem;
    }
  }
  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .checkbox-list {
      display: ${(props) => (props.$isMulti ? 'grid' : 'flex')};
      grid-template-columns: repeat(5, 1fr);
      row-gap: 0.5rem;
      column-gap: 2rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    .checkbox-list {
      display: ${(props) => (props.$isMulti ? 'grid' : 'flex')};
      grid-template-columns: repeat(4, 1fr);
      row-gap: 0.5rem;
      column-gap: 2rem;
    }
  }
  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    .checkbox-list {
      display: ${(props) => (props.$isMulti ? 'grid' : 'flex')};
      grid-template-columns: repeat(3, 1fr);
      row-gap: 0.5rem;
      column-gap: 2rem;
    }
  }
`

export const FormInputNumber = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  input {
    max-width: 15rem;
    min-width: fit-content;
  }
`

export const FormInputTable = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  table {
    width: 100%;
    margin: 0.5rem 0;

    thead {
      text-align: left;
    }
    input[type='number'] {
      width: 8rem;
    }
  }
`

export const ErrorMessage = styled.span`
  width: 100% !important;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red._500};
  font-size: 0.875rem;
  font-weight: 500;
`

export const FormInputRadio = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  .wrapper_radio {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .radio-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 2.5rem;
    margin-bottom: 0.5rem;

    .input-radio {
      display: flex;
      flex-direction: row;
      input {
        height: 1.15rem;
        width: 1.15rem;
        max-width: 1.15rem;
        min-width: 1.15rem;
      }

      p {
        margin-left: 0.75rem;
      }
    }
  }
`

export const FormImageFileField = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;

  .form-file {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
  }

  .form-file-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 14rem;

    border-radius: 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue._800};
    font-size: 1rem;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      opacity: 0.9;
    }

    span:first-child {
      padding: 0rem 0.15rem;
      border-radius: 0.175rem;
      border: 1px solid ${({ theme }) => theme.colors.white};
      margin-right: 0.5rem;
    }
  }

  .form-file-img {
    width: 100%;
    height: 10rem;
    display: flex;
    flex-direction: column;

    button {
      border: none;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.white};
      padding: 0.15rem 0.35rem;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.gray._500};
      align-self: flex-end;
      position: absolute;
      margin: 0.5rem 0.5rem 0 0;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.9;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const FormEditorContentField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  .form-text-label {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue._800};
    margin-bottom: 0.5rem;
  }

  .quill {
    width: 100%;
    height: 100%;
  }
`

export const FormColorField = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;
`

export const FormIconField = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: column;

  .icon-label {
    .btn-info {
      background-color: transparent;
      border: none;
      svg {
        color: ${({ theme }) => theme.colors.blue._800};
        width: 0.875rem;
        height: 0.875rem;
        margin-left: 0.35rem;
        margin-bottom: 0.25rem;
      }

      &:hover {
        cursor: help;
      }
    }
  }
`

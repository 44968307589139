/* eslint-disable @typescript-eslint/indent */
import { ChangeEvent, Fragment } from 'react'
import {
  CpfField,
  EmailField,
  FileField,
  CheckboxField,
  PhoneField,
  SelectField,
  TextField,
  TextareaField,
  TimeField,
  UserField,
  ManagerField
} from './Fields'
import { AttributeModel, AttributeOptionModel } from '../../entities'
import { OptionListI } from 'hooks'
import DownloadField from './Fields/DownloadField'
import { setOptions } from './util'
import SearchField from './Fields/SearchField'

type Props = {
  attributes: AttributeModel[]
  isVip: boolean
  setUserName: (value: string) => void
  handleCheckboxExtraQuestions: (
    e: ChangeEvent<HTMLInputElement>,
    option: AttributeOptionModel,
    attName: string,
  ) => void
  handleSelectExtraQuestions: (
    option: string,
    attribute: AttributeModel,
    firstLevel?: boolean,
  ) => void
  optionListSelected?: OptionListI[]
  handleEquipmentSelection?: (option: string, quantity: number) => void
  firstLevel?: boolean
  previousOptions?: any
}

export const InputsToRender = ({
  attributes,
  isVip,
  setUserName,
  handleCheckboxExtraQuestions,
  handleSelectExtraQuestions,
  optionListSelected,
  handleEquipmentSelection,
  firstLevel = false,
  previousOptions
}: Props) => {
  const sortAttributeForm = attributes.sort(
    (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
  )

  return (
    <>
      {attributes?.length > 0 &&
        sortAttributeForm.map((attribute) => (
          <Fragment key={attribute.id}>
            {attribute.type === 'user' && !(attribute.isHiddenToVip && isVip) && (
              <UserField attribute={attribute} setUserName={setUserName} />
            )}

            {attribute.type === 'file' && !(attribute.isHiddenToVip && isVip) && (
              <FileField attribute={{ ...attribute, isMulti: true }} />
            )}

            {attribute.type === 'text' && !(attribute.isHiddenToVip && isVip) && (
              <TextField attribute={attribute} />
            )}

            {attribute.type === 'CPF' && !(attribute.isHiddenToVip && isVip) && (
              <CpfField attribute={attribute} />
            )}

            {attribute.type === 'textarea' && !(attribute.isHiddenToVip && isVip) && (
              <TextareaField attribute={attribute} />
            )}
            {attribute.type === 'phone' && !(attribute.isHiddenToVip && isVip) && (
              <PhoneField attribute={attribute} />
            )}

            {attribute.type === 'time' && !(attribute.isHiddenToVip && isVip) && (
              <TimeField attribute={attribute} />
            )}

            {attribute.type === 'email' && !(attribute.isHiddenToVip && isVip) && (
              <EmailField attribute={attribute} />
            )}

            {attribute.type === 'search' && !(attribute.isHiddenToVip && isVip) && (
              <SearchField attribute={attribute} searchTerms={previousOptions} />
            )}

            {attribute.type === 'download' &&
              attribute?.optionsSelectElement?.length > 0 &&
              !(attribute.isHiddenToVip && isVip) && <DownloadField attribute={attribute} />}

             {attribute.type === 'manager' && !(attribute.isHiddenToVip && isVip) && (
              <ManagerField attribute={attribute}/>
             )
             }
            {attribute.type === 'checkbox' &&
              attribute?.optionsSelectElement?.length > 0 &&
              !(attribute.isHiddenToVip && isVip) && (
                <>
                  <CheckboxField
                    attribute={attribute}
                    handleOptionsSelected={(e, option) => {
                      handleCheckboxExtraQuestions(e, option, attribute.id.toString())
                    }}
                  />
                  {attribute.optionsSelectElement.length > 0 &&
                    attribute.optionsSelectElement.map((option) => {
                      const optSelect = optionListSelected?.find((op) => op.value === option.value)

                      if (option?.attributes?.length > 0 && optSelect?.value === option.value) {
                        return (
                          <Fragment key={option.id}>
                            <InputsToRender
                              attributes={option.attributes}
                              isVip={isVip}
                              setUserName={setUserName}
                              handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
                              handleSelectExtraQuestions={handleSelectExtraQuestions}
                              optionListSelected={optionListSelected}
                              handleEquipmentSelection={handleEquipmentSelection}
                            />
                          </Fragment>
                        )
                      }
                      return <Fragment key={option.id}></Fragment>
                    })}
                </>
              )}

            {attribute.type === 'select' &&
              attribute?.optionsSelectElement?.length > 0 &&
              !(attribute.isHiddenToVip && isVip) && (
                <>
                  <SelectField
                    attribute={attribute}
                    options={setOptions(
                      attribute.optionsSelectElement.sort(
                        (a, b) => (a.weight as number) - (b.weight as number)
                      )
                    )}
                    isMulti={attribute.isMulti}
                    handleOptionSelected={(option) => {
                      handleSelectExtraQuestions(option, attribute)
                    }}
                  />
                  {attribute.optionsSelectElement.length > 0 &&
                    attribute.optionsSelectElement.map((option: AttributeOptionModel) => {
                      const optSelect = optionListSelected?.find(
                        (op) => op.value === option.value && attribute.id.toString() === op.attName
                      )
                      const newPreviousOptions = previousOptions ? `${previousOptions as string}>${option.value}` : option.value

                      if (option?.attributes?.length > 0 && optSelect) {
                        return (
                          <Fragment key={option.id}>
                            <InputsToRender
                              attributes={option.attributes}
                              isVip={isVip}
                              setUserName={setUserName}
                              handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
                              handleSelectExtraQuestions={handleSelectExtraQuestions}
                              optionListSelected={optionListSelected}
                              handleEquipmentSelection={handleEquipmentSelection}
                              previousOptions={newPreviousOptions}
                            />
                          </Fragment>
                        )
                      }
                      return <Fragment key={option.id}></Fragment>
                    })}
                </>
              )}
          </Fragment>
        ))}
    </>
  )
}

/* eslint-disable no-multiple-empty-lines */
/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios'
import { UserAuthenticationModel } from '../entities'

const url = process.env.REACT_APP_API_AUTH

const api = axios.create({
  baseURL: url
})

export const authenticate = async (login: string, password: string): Promise<UserAuthenticationModel> => {
  const payload = {
    credentials: {
      login,
      password
    }
  }
  const response = await api.post('/user/authenticate', payload)
  return response.data
}


/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FieldArray, FieldValues, PathValue, useFieldArray, useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { AttributeModel } from '../../../../entities'
import AttributeField from './AttributeField'

import {
  AddBtnAdmField,
  RemoveBtnAdmField,
  WrapperAdmField,
  WrapperAdmFieldList,
  WrapperFields
} from './styles'
import { AttOptionsProps } from './Select'

export type IAttributeProps = Pick<AttributeModel, 'question' | 'required' | 'isHiddenToVip' | 'type' | 'positionOrder' | 'isVisibleToDescription'> & { optionsSelectElement: AttOptionsProps[], name?: string | null } // REMOVE name

type Props = {
  attributeFieldName: any
  firstLevel?: boolean // TO AVOID INFINITY CYCLE OF SELECT <-> SELECT
}

const AttributeFieldList = <T extends FieldValues>({
  attributeFieldName,
  firstLevel = false
}: Props) => {
  const { control, getValues, setValue } = useFormContext<T>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: attributeFieldName,
    keyName: '_id'
  })

  const updateAttPosition = (attributes: T[]) => {
    const _attributes: T[] = []
    attributes.forEach((att, idx) => {
      _attributes.push({ ...att, positionOrder: idx + 1 })
    })
    return _attributes
  }

  const reorderList = (e: DragEndEvent) => {
    if (!e.over) return

    if (e.active.id !== e.over.id) {
      const oldIdx = fields.findIndex((el) => el._id === e.active.id)
      const newIdx = fields.findIndex((el) => el._id === e.over?.id)
      const _fields = arrayMove(getValues(attributeFieldName), oldIdx, newIdx)
      const _newFields = updateAttPosition(_fields as T[])
      setValue(attributeFieldName, _newFields as PathValue<T, any>)
    }
  }

  return (
    <WrapperFields>
      <label htmlFor="attributes">Crie novas perguntas:</label>
      <DndContext onDragEnd={reorderList} autoScroll={false}>
        <SortableContext items={fields.map((el) => el._id)}>
          <WrapperAdmFieldList>
            {fields.map((field, index) => (
              <WrapperAdmField key={field._id}>
                <AttributeField<T>
                  attribute={field as unknown as AttributeModel}
                  id={field._id}
                  question={`${attributeFieldName}.${index}.question`}
                  type={`${attributeFieldName}.${index}.type`}
                  positionOrder={`${attributeFieldName}.${index}.positionOrder`}
                  isHiddenToVip={`${attributeFieldName}.${index}.isHiddenToVip`}
                  isVisibleToDescription={`${attributeFieldName}.${index}.isVisibleToDescription`}
                  required={`${attributeFieldName}.${index}.required`}
                  optionsSelectElementFieldName={`${attributeFieldName}.${index}.optionsSelectElement`}
                  // firstLevel={false}
                  firstLevel={firstLevel}
                />
                <RemoveBtnAdmField>
                  <button
                    type="button"
                    onClick={() => {
                      remove(index)
                    }}
                    title="Remover campo"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </RemoveBtnAdmField>
              </WrapperAdmField>
            ))}
          </WrapperAdmFieldList>
        </SortableContext>
      </DndContext>
      <AddBtnAdmField>
        <button
          type="button"
          onClick={() => {
            append({
              // name: `question-${crypto.randomUUID()}`,
              positionOrder: fields.length + 1,
              question: '',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              type: 'text',
              placeholder: null,
              isMulti: null,
              columnsLabel: null,
              isVisibleToDescription: true,
              maxLength: 25000,
              fileFormats: null,
              name: crypto.randomUUID()// REMOVE
            } as FieldArray<T, any>)
          }}
          title="Adicionar novo campo"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <p>Adicionar pergunta</p>
      </AddBtnAdmField>
    </WrapperFields>
  )
}

export default AttributeFieldList

/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AddButton,
  AdmLayout,
  DeleteFormModal,
  EnableFormModal,
  Pagination,
  SearchForm,
  Spinner
} from 'components'
import { useGetFormList } from 'hooks'
import { FormI, FormModel } from '../../../entities'

import { CardLabelColor } from 'components/Card/styles'
import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperBtnSearchForm,
  WrapperContent,
  WrapperFormList
} from '../styles'

export const FormsAdmin = () => {
  const navigate = useNavigate()
  const { formList, isLoadingForms, getFormList, pagination, setFormList } = useGetFormList()

  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const refEnableModal = useRef<HTMLDialogElement>(null)

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenEnableModal, setIsOpenEnableModal] = useState(false)
  const [formSelected, setFormSelected] = useState<FormModel | FormI | null>(null)
  const [isSearchData, setIsSearchData] = useState(false)

  const toggleEnableModal = () => {
    if (refEnableModal.current) {
      if (isOpenEnableModal) {
        setIsOpenEnableModal(false)
        refEnableModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEnableModal(true)
      refEnableModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const handleUpdateData = () => {
    if (isSearchData) {
      window.location.reload()
    } else {
      getFormList()
    }
  }

  useEffect(() => {
    getFormList()
  }, [])

  return (
    <AdmLayout title="Administração de formulários">
      <WrapperContent>
        <TitlePage>Formulário</TitlePage>
        <WrapperBtnSearchForm $isSearchData={isSearchData}>
          {!isSearchData && (
            <AddButton
              text="Criar Formulário"
              onClick={() => {
                navigate('/admin/new/form')
              }}
            />
          )}
          <SearchForm
            handleFormSelected={(form) => {
              setFormList([form])
              setIsSearchData(true)
            }}
            updateData={() => {
              setIsSearchData(false)
              getFormList()
            }}
          />
        </WrapperBtnSearchForm>
        <WrapperFormList>
          <label>Formulários cadastrados:</label>

          {isLoadingForms && <Spinner />}
          {!isLoadingForms && formList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhum formulário encontrado!</p>
            </EmptyListMessage>
          )}
          {!isLoadingForms && formList.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className="column-color"></th>
                  <th className="column-title">Título</th>
                  <th className="column-cardName">Card associado</th>
                  <th className="column-action">Ação</th>
                  <th className="column-active">Ativo</th>
                </tr>
              </thead>
              <tbody>
                {formList.map((form: FormModel) => (
                  <tr key={form.id}>
                    <td className="column-color">
                      <CardLabelColor
                        $colorLabel={form.associatedCard?.color ?? '#ababab'}
                        $height="90% !important"
                      />
                    </td>
                    <td className="column-title">{form.title}</td>
                    <td className="column-cardName">
                      {form.ancestorsCardTreeName ? (
                        form.ancestorsCardTreeName
                          .split('>')
                          .map((name: any, index: number) => <p key={index}>{name}</p>)
                      ) : (
                        <p style={{ fontSize: '0.85rem' }}>{form.associatedCard?.name}</p>
                      )}
                    </td>
                    <td className="column-action">
                      <button
                        className="action-edit"
                        onClick={() => {
                          navigate(`/admin/edit/form/${form.id.toString()}`)
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="action-remove"
                        onClick={() => {
                          setFormSelected(form)
                          toggleDeleteModal()
                        }}
                        title="Remover"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                    <td className="column-active">
                      <input
                        type="checkbox"
                        checked={form.isEnable}
                        onChange={() => {
                          setFormSelected(form)
                          toggleEnableModal()
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperFormList>
        {!isLoadingForms && formList.length > 0 && !isSearchData && (
          <Pagination
            currentPage={pagination.page + 1}
            onNextPage={() => {
              getFormList(pagination.page + 1)
            }}
            onPreviousPage={() => {
              getFormList(pagination.page - 1)
            }}
            hasNextPage={pagination.next}
            hasPreviousPage={pagination.prev}
          />
        )}
      </WrapperContent>
      <ModalWrapper ref={refEnableModal}>
        {isOpenEnableModal && formSelected && (
          <EnableFormModal
            formSelected={formSelected as FormModel}
            toggleModal={toggleEnableModal}
            updateData={() => {
              handleUpdateData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && formSelected && (
          <DeleteFormModal
            toggleModal={toggleDeleteModal}
            formSelected={formSelected as FormModel}
            updateData={() => {
              handleUpdateData()
            }}
          />
        )}
      </ModalWrapper>
    </AdmLayout>
  )
}

import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'components'
import { FormModel } from '../../entities'
import { deleteForm } from 'services/api'

import { ModalBodyDelete } from 'components/Modal/styles'
import { ErrorMessage } from 'components/CardAdmin/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  formSelected: FormModel
}

const DeleteFormModal = ({ toggleModal, formSelected, updateData }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleDeleteAdmin = async (): Promise<void> => {
    if (!isConfirm) {
      setErrorMessage('* Campo obrigatório')
      return
    }
    try {
      setIsLoading(true)
      const response = await deleteForm(formSelected?.id as string)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao remover o formulário!')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover formulário"
      handleCloseModal={toggleModal}
      handleSubmit={handleDeleteAdmin}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#cf1f3f"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover este formulário?</p>
        </div>
        <p className="admName">{formSelected?.title}</p>
        <span className="warning-msg">
          <p>Ao excluir este formulário você não poderá recuperá-lo!</p>
        </span>
        <div className="confirm-action">
          <span className="confirm-msg">
            <input
              type="checkbox"
              onChange={() => {
                setIsConfirm(!isConfirm)
                setErrorMessage('')
              }}
            />
            <p>Confirmo que li e estou ciente da remoção do formulário.</p>
          </span>
          {errorMessage && <ErrorMessage $fontSize={'0.75rem'}>{errorMessage}</ErrorMessage>}
        </div>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteFormModal

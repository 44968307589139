import { ChangeEvent, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { AttributeModel, AttributeOptionModel } from '../entities'

export interface OptionListI extends AttributeOptionModel {
  attName: string
}

type Props = {
  methods: UseFormReturn<any, any, undefined>
}

export const useSetExtraQuestions = ({ methods }: Props) => {
  const [optionListSelected, setOptionListSelected] = useState<OptionListI[] | any>([])

  const handleCheckboxExtraQuestions = (
    e: ChangeEvent<HTMLInputElement>,
    option: AttributeOptionModel,
    attName: string
  ) => {
    const newOptionListSelected = setExtraQuestionsFromCheckboxField({
      optSelected: option,
      isChecked: e.target.checked,
      attName,
      optionListSelected,
      methods
    })
    setOptionListSelected(newOptionListSelected)
  }

  const handleSelectExtraQuestions = (optSelected: string, attribute: AttributeModel) => {
    const newOptionListSelected = setExtraQuestionsFromSelectField({
      optSelected,
      attribute,
      optionListSelected,
      methods
    })
    setOptionListSelected(newOptionListSelected)
  }

  return {
    optionListSelected,
    setOptionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions
  }
}

type SelectProps = {
  optSelected: string
  attribute: AttributeModel
  optionListSelected: OptionListI[]
  methods: UseFormReturn<FieldValues, any, undefined>
}

export const setExtraQuestionsFromSelectField = ({
  optSelected,
  attribute,
  optionListSelected,
  methods
}: SelectProps): OptionListI[] => {
  let newOptionListSelected = optionListSelected

  if (optionListSelected.length > 0) {
    // Filter the options list to no repeat the same question
    newOptionListSelected = optionListSelected.filter(
      (opt) => opt.attName !== attribute.id.toString()
    )

    // Unregister all the attributes from de same question
    const selectedOption = optionListSelected.find(
      (opt) => opt.attName === attribute.id.toString()
    )

    // Update optionListSelected and unregister all the attributes dependencies
    if (selectedOption) {
      newOptionListSelected = unregisterValuesInLowLevel(
        selectedOption,
        methods,
        newOptionListSelected
      )
    }
  }
  // Get the data from the option selected
  const attSelected = attribute.optionsSelectElement.find((option) => option.value === optSelected)

  if (attSelected) {
    const newOptionSelect = { ...attSelected, attName: attribute.id.toString() }
    return [...newOptionListSelected, newOptionSelect]
  }
  return newOptionListSelected
}

type CheckboxProps = {
  optSelected: AttributeOptionModel
  isChecked: boolean
  optionListSelected: OptionListI[]
  methods: UseFormReturn<FieldValues, any, undefined>
  attName: string
}

export const setExtraQuestionsFromCheckboxField = ({
  optSelected,
  isChecked,
  attName,
  optionListSelected,
  methods
}: CheckboxProps): OptionListI[] => {
  let newOptionListSelected = optionListSelected

  if (isChecked) {
    const newOptionSelect = { ...optSelected, attName }
    return [...newOptionListSelected, newOptionSelect]
  } else {
    newOptionListSelected = newOptionListSelected.filter((op) => op.value !== optSelected.value)
    if (optSelected.attributes.length > 0) {
      optSelected.attributes?.forEach((att) => {
        methods.unregister(att.id.toString())
      })
    }
    return newOptionListSelected
  }
}

const unregisterValuesInLowLevel = (
  option: AttributeOptionModel | OptionListI,
  methods: UseFormReturn<FieldValues, any, undefined>,
  optionListSelected: OptionListI[]
) => {
  let newOptionListSelected = optionListSelected
  if (option.attributes.length > 0) {
    option.attributes.forEach((attribute) => {
      methods.unregister(attribute.id.toString())
      newOptionListSelected = newOptionListSelected.filter(
        (option) => option?.attName !== attribute.id.toString()
      )
      if (attribute.optionsSelectElement.length > 0) {
        attribute.optionsSelectElement.forEach((opt) => {
          unregisterValuesInLowLevel(opt, methods, newOptionListSelected)
        })
      }
    })
  }

  return newOptionListSelected
}

import { getFileUrl, getMultiplePrivateFile } from 'services/fileManager'

const getUrlFile = async (fileData: File) => {
  const formData = new FormData()
  formData.append('files', fileData)
  const response = await getFileUrl(formData)
  return response
}

export const setFileField = async (data: Record<string, string | File>) => {
  let attachments: Array<{ file_url: string, file_token: any, file_name: string }> = []
  const tokenFile = process.env.REACT_APP_TOKEN_RECIVED_FILE_MANAGER

  for (const value of Object.values(data)) {
    // Condition for Array<File> value
    if (Array.isArray(value) && value[0] instanceof File) {
      const response = await getMultiplePrivateFile(value)
      if (!response) {
        throw new Error()
      }
      response.forEach((file: any) =>
        attachments.push({
          file_url: file.data[0].name,
          file_name: file.data[0].originalname,
          file_token: tokenFile
        })
      )
    }
    // Condition for File value
    if (!Array.isArray(value) && value instanceof File) {
      const respFileManager = await getUrlFile(value)
      if (!respFileManager.data) {
        throw new Error()
      }
      attachments = [
        {
          file_url: respFileManager.data[0].name,
          file_name: respFileManager.data[0].originalname,
          file_token: tokenFile
        }
      ]
    }
  }
  return attachments
}

import styled from 'styled-components'

export const ErrorMessage = styled.span<{ $fontSize?: string }>`
  width: 100% !important;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red._500};
  font-size:${(props) => (props.$fontSize ?? '0.875rem')};
  font-weight: 500;
`

export const Breadcrumb = styled.div`
  display: flex;
  margin: 1rem 0;
  font-size: 0.875rem;
  & > * {
    margin-right: 0.75rem;
    color: ${({ theme }) => theme.colors.blue._800} !important;
  }

  a {
    color: ${({ theme }) => theme.colors.blue._800} !important;
    &:hover {
      text-decoration: underline;
    }
  }

  .initial-link::after,
  .crumb::after {
    content: '>';
    margin-left: 0.75rem;
  }

  .crumb:last-child:after {
    display: none;
  }
`

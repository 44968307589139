import styled from 'styled-components'
import { breakQueriesMaxMin } from 'styles/Breakpoints'

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const IconListWrap = styled.div`
  margin-top: 0.15rem;
  width: 100%;
  z-index: 9999;
`

export const IconListInner = styled.div`
  border: 1px solid #ccc;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 200px;
`

export const IconList = styled.ul`
  list-style: none;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  max-height: 200px;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._400};

  background-color: ${({ theme }) => theme.colors.white};
  line-height: 1.5;
  background-clip: padding-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  li {
    margin: 0;
    display: inline-block;
  }
`

export const IconItem = styled.li`
  button {
    appearance: none;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    padding: 0.5rem;
    transition: background-color linear 0.15s;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background: #bae2f3;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      color: ${({ theme }) => theme.colors.gray._700};
    }
  }
`

export const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: fit-content;
  height: 2.3rem;
  padding: 0 0.5rem 0 0;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._400};

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray._500};
  line-height: 1.5;
  background-clip: padding-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.gray._500};
    background-color: ${({ theme }) => theme.colors.gray._100};
    appearance: none;
    outline: 0;
  }

  .btn-icon-info {
    display: flex;
    align-self: center;
    justify-self: flex-start;
    color: ${({ theme }) => theme.colors.gray._500};
    margin-left: 1rem;

    .btn-info-text {
      border: none;
      width: fit-content;
      min-width: 15rem;
      height: fit-content;
      margin: 0;
      margin-left: 0.5rem;
      padding: 0 0.75rem;

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray._800};
        color: ${({ theme }) => theme.colors.black};
        font-size: 1rem;
      }
    }

    .btn-info-text-default{
      &::placeholder {
        color: ${({ theme }) => theme.colors.gray._700};
        font-size: .875rem;
      }
    }

    svg {
      margin-top: 0.15rem;
      color: ${({ theme }) => theme.colors.gray._700};
    }
  }

  .btn-icon-info-default{
    svg {
      color: ${({ theme }) => theme.colors.gray._400};
    }
  }

  .btn-menu {
    border: none;
    background-color: transparent;
    height: 100%;
    width: fit-content;
    min-width: 2rem;
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
    svg {
      color: ${({ theme }) => theme.colors.gray._700};
    }
  }

  ${breakQueriesMaxMin(900, 715)} {
    .btn-info-text {
      min-width: 10rem !important;
    }
  }

  ${breakQueriesMaxMin(715, 500)} {
    .btn-info-text {
      min-width: 0rem !important;
      padding: 0 0.25rem !important;
    }
    .btn-menu{
      margin-left: 0;
    }
  }
`

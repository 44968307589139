import { IAttributeProps } from 'components/TicketForms/Fields/AttributeFields/AttributeFieldList'
import { AttributeModel, AttributeOptionModel } from '../../../entities'

type AttToShowProps = {
  attrsCreated: IAttributeProps[]
  attrsReused: any[]
}

export const setAttributeToShow = ({ attrsCreated, attrsReused }: AttToShowProps): AttributeModel[] => {
  const _attrCreated = [...attrsCreated]
  const list: AttributeModel[] = [...attrsReused]

  _attrCreated.forEach((attr) => {
    const _options: AttributeOptionModel[] = []
    if (attr?.optionsSelectElement.length > 0) {
      attr?.optionsSelectElement.forEach((opt) => {
        if (opt?.attributes.length > 0) {
          const _atts = setAttributes(opt.attributes)
          _options.push({
            ...opt,
            attributes: _atts ?? [],
            id: crypto.randomUUID(),
            title: null,
            value: '',
            weight: null,
            propertyId: null,
            categoryId: null,
            type: null,
            group: null,
            categoryName: null,
            openingStatus: null
          })
        } else {
          _options.push({
            ...opt,
            id: crypto.randomUUID(),
            title: null,
            value: '',
            attributes: [],
            weight: null,
            propertyId: null,
            categoryId: null,
            type: null,
            group: null,
            categoryName: null,
            openingStatus: null
          })
        }
      })
    }
    list.push({
      ...attr,
      id: crypto.randomUUID(),
      optionsSelectElement: [..._options]
    } as AttributeModel)
  })

  return list
}

const setAttributes = (attributes: IAttributeProps[], previousAtt: AttributeModel[] = []) => {
  const newData: AttributeModel[] = previousAtt

  attributes.forEach((attribute) => {
    const _options: AttributeOptionModel[] = []
    if (attribute?.optionsSelectElement && attribute?.optionsSelectElement.length > 0) {
      attribute?.optionsSelectElement.forEach((option) => {
        if (option?.attributes.length > 0) {
          setAttributes(option?.attributes, newData)
        } else {
          _options.push({
            ...option,
            id: crypto.randomUUID(),
            title: null,
            value: '',
            attributes: [],
            weight: null,
            propertyId: null,
            categoryId: null,
            type: null,
            group: null,
            categoryName: null,
            openingStatus: null
          })
        }
      })
    }

    newData.push({
      ...(attribute as AttributeModel),
      optionsSelectElement: _options,
      id: crypto.randomUUID()
    })
  })

  return newData
}

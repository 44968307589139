import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, ContainerLoading, OldTicketListAll, PageModel } from 'components'
import OldFilters from 'components/ConsultOldTicketList/OldFilters'
import { IPagination } from 'components/ConsultOldTicketList/OldTicketListAll'
import { ContainerOldTicketsHeading } from 'components/ConsultOldTicketList/styles'
import { AuthContext } from 'contexts/auth'
import { OldTicketI, TicketI } from 'entities'
import { IDatabaseFilter, useGetOldTickets } from 'hooks/useGetOldTickets'
import { ContainerConsultTicket } from 'pages/ConsultTicketList/styles'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getOldTicket } from 'services/connector'
import { ContainerConsultOldTicket } from './styles'

const ConsultOldTicketList = () => {
  const [ticketById, setTicketById] = useState<OldTicketI[] | null>(null)
  const [requester, setRequester] = useState('')
  const [dateStart, setDateStart] = useState<number | null>(null)
  const [dateEnd, setDateEnd] = useState<number | null>(null)
  const [database, setDatabase] = useState<IDatabaseFilter>('outdated')
  const [isLoadingTicketById, setIsLoadingTicketById] = useState(false)
  const maxLength = 250
  const { isLoadingOldTickets, oldTicketList, pagination, setPagination } = useGetOldTickets({ starDate: dateStart, endDate: dateEnd, requester, base: database })
  const { logout } = useContext(AuthContext)
  const navigate = useNavigate()

  const getOldTicketDetails = async (ticketId: string): Promise<void> => {
    setIsLoadingTicketById(true)
    try {
      const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin

      if (!userLogin) {
        toast.error(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
        setTimeout(() => {
          logout()
        }, 2000)
        return
      }

      const response = await getOldTicket(ticketId, database)
      if (!response) {
        throw new Error()
      }
      navigate(`/old-ticket/detail/${ticketId}/${database}`)
    } catch (error: any) {
      setTicketById([])
      if (error?.response?.data?.err === 'Chamado não encontrado' || error?.response?.data?.err === 'Usuário não é o solitante do chamado') {
        toast.error('Ops 😥, Chamado não encontrado! ')
      } else {
        toast.error('Ops 😥, ocorreu um erro ao recuperar o chamado ')
      }
    } finally {
      setIsLoadingTicketById(false)
    }
  }
  const handleSearch = async (value: string) => {
    if (value) {
      await getOldTicketDetails(value)
    }
  }

  const handlePeriod = (dateStart: number, dateEnd: number) => {
    setDateStart(dateStart)
    setDateEnd(dateEnd)
  }
  return (
    <PageModel title='Consulta de Solicitação'>
      <ContainerConsultOldTicket $isLoadingTickets={isLoadingTicketById || isLoadingOldTickets}>
        <OldFilters
          setPeriod={handlePeriod}
          setRequester={setRequester}
          setDatabase={setDatabase}
          handleSearch={handleSearch}
          setTicketById={setTicketById}
        />
        {isLoadingOldTickets || isLoadingTicketById ? (
          <ContainerLoading />
        ) : (
          <OldTicketListAll
            pagination={pagination}
            setPagination={setPagination}
            ticketList={ticketById ?? oldTicketList}
            base={database}
          />
        )
        }
      </ContainerConsultOldTicket>
    </PageModel>
  )
}

export default ConsultOldTicketList

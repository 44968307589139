import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import { AdmLayout, ButtonGoBack, EditFormContent } from 'components'
import { useGetForm } from 'hooks'

import {
  TitlePage,
  WrapperBtnGoBack,
  WrapperContent,
  WrapperFormLoading
} from 'pages/Admin/styles'

export const EditForm = () => {
  const { formId } = useParams()
  const navigate = useNavigate()

  const { form, getForm, isLoadingForm } = useGetForm()

  const getData = async () => {
    await getForm(formId as string)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdmLayout title="Novo formulário">
      <WrapperBtnGoBack>
        <ButtonGoBack
          onClick={() => {
            navigate('/admin/forms')
          }}
          className="btn-go-back"
        />
      </WrapperBtnGoBack>
      <WrapperContent>
        <TitlePage>Editar formulário</TitlePage>
        {isLoadingForm && (
          <WrapperFormLoading>
            <MoonLoader size={45} color={'#123abc'} />
            <p>
              Aguarde, já estamos <br /> carregando as informações
            </p>
          </WrapperFormLoading>
        )}
        {!isLoadingForm && form && <EditFormContent form={form} />}
      </WrapperContent>
    </AdmLayout>
  )
}

import { CategoryType, ICategory } from '../../../entities'
import { OptionListI } from 'hooks'

type PropsResponse = {
  category: ICategory | null
  title: string | null
}

export const getCategoryInfoFromSelectedOption = (optionsList: OptionListI[]): PropsResponse => {
  const option = optionsList.find((option) => option.categoryId !== null)
  if (option?.categoryId) {
    return {
      category: {
        name: option.categoryName as string,
        type: option.type as CategoryType,
        group: option.group as string,
        status: option.openingStatus ?? 'DIR',
        categoryId: option.categoryId
      },
      title: option?.title
    }
  }

  return { category: null, title: null }
}

export const SizeBreakpoint = {
  xsm: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1780,
  xxxxl: 2787
}

export const breakQueriesMaxMin = (max: number, min: number) =>
  `@media (min-width: ${min}px) and (max-width: ${max}px)`
export const breakQueriesMax = (size: number) => `@media (max-width: ${size}px)`
export const breakQueriesMin = (size: number) => `@media (min-width: ${size}px)`

import ModalContent from 'components/Modal/ModalContent'
import { ModalBodyInfo } from 'components/Modal/styles'
import NewsListContent from 'components/News/NewsList'
import { INews } from 'entities'

type Props = {
  news: Pick<INews, 'imageUrlData' | 'description'> | null
  toggleModal: () => void
}

const PreviewNewsModal = ({ news, toggleModal }: Props) => {
  return (
    <ModalContent
      title={'Visualização do destaque'}
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
    >
      <ModalBodyInfo>
        {news && (
          <NewsListContent
            isLoading={false}
            newsList={[
              {
                id: 1,
                imageUrl: '',
                description: news.description,
                imageUrlData: news.imageUrlData,
                weight: 1,
                isEnable: true
              }
            ]}
            border={true}
          />
        )}
      </ModalBodyInfo>
    </ModalContent>
  )
}
export default PreviewNewsModal

import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const getRatingStyles = (colors: any) => {
  const ratingStyles = {
    full: {
      first: {
        transition: 'none'
      },
      second: {
        transition: 'none',
        color: colors.primary
      }
    },
    half: {
      first: {
        transition: 'none',
        color: colors.primary
      },
      second: { transition: 'none', color: '#ddd' }
    },
    zero: {
      first: {
        transition: 'none'
      },
      second: {
        transition: 'none',
        color: '#ddd'
      }
    }
  }
  return ratingStyles
}

export const ContainerFeedback = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%rem;
  height: auto;
  margin-top: 1.5rem;

  // Align with the header
  padding-right: 4rem;
  padding-left: 3rem;

  .feedback-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 37.25rem;
    height: 26rem;
    padding: 5rem 4rem;
    border-radius: 0.875rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._900};
    gap: 2rem;

    .feedback-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
    }

    .feedback-btn {
      display: flex;
      gap: 2rem;

      button:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10rem;
        height: 2.3rem;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 0.5rem;
        border: 2px solid ${({ theme }) => theme.colors.blue._500};
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.blue._500};
        transition: 0.2s;
        cursor: pointer;

        &:hover {
          background-color: ${({ theme }) => theme.colors.blue._600};
          color: ${({ theme }) => theme.colors.white};
        }

        :disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    .feedback-content {
      width: auto;
      height: auto;
      padding: 3rem 2rem;
    }
  }
`

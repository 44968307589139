import { useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useClickOutside } from 'hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { BtnGroup, ColorWrapper } from './styles'

type Props = {
  onChange: (value: string) => void
  color: string
}

const ColorPicker = ({ onChange, color }: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside({ ref, setIsOpenMenu })
  return (
    <ColorWrapper ref={ref}>
      <BtnGroup $color={color}>
        <span className="btn-info">
          <span></span>
          <p>{color}</p>
        </span>
        <button
          className="btn-menu"
          type="button"
          onClick={() => {
            setIsOpenMenu(!isOpenMenu)
          }}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      </BtnGroup>
      {isOpenMenu && (
        <HexColorPicker
          color={color}
          onChange={(newColor: string) => {
            onChange(newColor)
          }}
        />
      )}
    </ColorWrapper>
  )
}

export default ColorPicker

import { KeyboardEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { MultiValue, SingleValue } from 'react-select'
import { SearchOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Select } from '..'
import { useGetColorFromTicketStatus } from '../../hooks'
import { TicketI } from '../../entities'
import { OptionSelect } from 'components/Select/Select'
import { styles, options } from './utils'

import { ContainerTicketsContent, ContainerTicketsHeading } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

type Props = {
  ticketList: TicketI[]
  setStatus: (value: string) => void
  setIndex: (index: number) => void
  setTicketById: (value: null) => void
  index: number
  hasMoreTickets: boolean
  listSize: number
  handleSearch: (value: string) => void
}

const TicketListAll = ({
  ticketList,
  setStatus,
  setIndex,
  index,
  hasMoreTickets,
  listSize,
  handleSearch,
  setTicketById
}: Props) => {
  const { getColor } = useGetColorFromTicketStatus()
  const [ticketId, setTicketId] = useState('')

  const handleSelect = (e: SingleValue<OptionSelect> | MultiValue<OptionSelect>) => {
    const value = (e as SingleValue<OptionSelect>)?.value
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (value || value === '') {
      setIndex(listSize)
      setStatus(value as string)
      setTicketById(null)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(ticketId)
    }
  }

  return (
    <>
      <ContainerTicketsHeading>
        <Breadcrumb goToHome={true} />
        <div className="tickets-filters">
          <div className="tickets-filter search-ticket">
            <label htmlFor="searchTicket">Filtrar:</label>
            <input
              type="search"
              name="searchTicket"
              placeholder="Digite o número do chamado"
              onChange={(e) => {
                setTicketId(e.target.value)
              }}
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
            />
            <button
              type="button"
              title="Procurar"
              onClick={() => {
                handleSearch(ticketId)
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div>
          <div className="tickets-filter">
            <label htmlFor="filterTicket">Status:</label>
            <Select
              onChange={(e) => {
                handleSelect(e)
              }}
              optionValues={options}
              styles={styles}
              noOptMessage="Status não encontrado"
            />
          </div>
        </div>
      </ContainerTicketsHeading>
      <ContainerTicketsContent>
        {ticketList.length === 0 && (
          <div className="not-called">
            <p>Nenhum chamado encontrado!</p>
          </div>
        )}
        {ticketList.length > 0 && (
          <div className="tickets-content">
            {ticketList
              .map((ticket) => (
                <Link
                  key={ticket.ticketID}
                  to={`/ticket/detail/${ticket.ticketID}`}
                  className="ticket-item"
                >
                  <div className="ticket-item-label">
                    <span
                      style={{
                        backgroundColor: getColor(ticket.status)
                      }}
                    >
                      {ticket.status.toUpperCase() === 'PROCESSANDO ATRIBUÍDO' ? 'Em Andamento' : ticket.status}
                    </span>
                    <span>{ticket.ticketID}</span>
                  </div>
                  <div className="ticket-item-content">
                    <div className="content-subject">
                      <span>Assunto:</span>
                      <span>{ticket.title}</span>
                    </div>
                    <div className="content-date">
                      <span>Data de abertura:</span>
                      <span>{ticket.date}</span>
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
        )}
        {ticketList.length > 0 && hasMoreTickets && (
          <Button
            type="submit"
            text="VER MAIS CHAMADOS"
            onClick={() => {
              setIndex(index + listSize)
            }}
          />
        )}
      </ContainerTicketsContent>
    </>
  )
}

export default TicketListAll

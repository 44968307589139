import { PaginationContainer } from './styles'

type Props = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  onNextPage: () => void
  onPreviousPage: () => void
  currentPage: number
}

const Pagination = (props: Props) => {
  const { hasNextPage, hasPreviousPage, onNextPage, onPreviousPage, currentPage } = props
  return (
    <PaginationContainer>
      <button disabled={!hasPreviousPage} onClick={onPreviousPage}>
        Anterior
      </button>
      <span>{currentPage}</span>
      <button disabled={!hasNextPage} onClick={onNextPage}>
        Próximo
      </button>
    </PaginationContainer>
  )
}

export default Pagination

import { ChangeEvent } from 'react'
import { ModalContent } from '..'
import { ModalBodyInfo } from 'components/Modal/styles'
import { InputsToRender } from 'components/TicketForms/InputsToRender'
import { AttributeModel, AttributeOptionModel } from '../../entities'

type Props = {
  toggleModal: () => void
  attributes: AttributeModel[]
  optionListSelected?: any[]
  title?: string | null
  handleCheckboxExtraQuestions?: (
    e: ChangeEvent<HTMLInputElement>,
    option: AttributeOptionModel,
    attName: string,
  ) => void
  handleSelectExtraQuestions?: (option: string, attribute: AttributeModel) => void
}

const PreviewFormModal = ({
  toggleModal,
  attributes,
  optionListSelected = [],
  title = null,
  handleCheckboxExtraQuestions = () => { },
  handleSelectExtraQuestions = () => {}
}: Props) => {
  return (
    <ModalContent
      title={title ?? 'Visualização do formulário'}
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
    >
      <ModalBodyInfo>
        <div className="form-inputs">
          <InputsToRender
            attributes={attributes}
            isVip={false}
            setUserName={() => {}}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
            optionListSelected={optionListSelected}
            handleEquipmentSelection={() => {}}
          />
        </div>
      </ModalBodyInfo>
    </ModalContent>
  )
}

export default PreviewFormModal

import { OptionSelect } from 'components/Select/Select'
import { AttributeModel } from '../../../entities'

export const findCategoryOpt = (
  option: number | string,
  categoryOptions: OptionSelect[]
): OptionSelect => {
  return categoryOptions.find((opt: any) => opt.value.id === option) as OptionSelect
}

export const findAttributeOptions = (
  attList: AttributeModel[],
  attributeOptions: OptionSelect[]
): OptionSelect[] => {
  const list: OptionSelect[] = []

  attList.forEach((att) => {
    const result = attributeOptions.find((attOpt: any) => attOpt.value.id === att.id)
    if (result) {
      list.push(result)
    }
  })
  return list
}

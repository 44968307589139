import { ButtonHTMLAttributes } from 'react'
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WrapperBtnGoBack } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLElement> {}

const ButtonGoBack = (props: Props) => {
  return (
    <WrapperBtnGoBack {...props}>
      <FontAwesomeIcon icon={faCircleChevronLeft} />
      Voltar
    </WrapperBtnGoBack>
  )
}

export default ButtonGoBack

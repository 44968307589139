import { RefObject, useEffect } from 'react'

type Props = {
  ref: RefObject<HTMLButtonElement | HTMLDivElement>
  setIsOpenMenu: (value: boolean) => void
}

export const useClickOutside = ({ ref, setIsOpenMenu }: Props) => {
  useEffect(() => {
    function handleClickOutside (event: MouseEvent) {
      if (
        ref.current &&
        event.target instanceof HTMLElement &&
        !ref.current.contains(event.target)
      ) {
        setIsOpenMenu(false)
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
}

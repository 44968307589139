import { TicketModelCostumer } from 'entities'
import React, { useEffect, useState } from 'react'
import { formatOldTicketData } from './utils/formatTicket'
import { toast } from 'react-toastify'
import { getOldTicket } from 'services/connector'
import { IDatabaseFilter } from './useGetOldTickets'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const useGetOldTicketDetails = (called_id: string | number, base: IDatabaseFilter) => {
  const [oldTicket, setOldTicket] = useState<TicketModelCostumer | null>(null)
  const [isLoadingOldTicket, setIsLoadingOldTicket] = useState(false)

  const getOldTicketDetails = async () => {
    setIsLoadingOldTicket(true)
    try {
      const response = await getOldTicket(called_id, base)
      if (!response) {
        setOldTicket(null)
        throw new Error()
      }

      const ticketformatted = formatOldTicketData(response, base)
      setOldTicket(ticketformatted)
    } catch (error) {
      console.log(error)

      toast.error('Ops 😥, ocorreu um erro ao recuperar o chamado ')
    } finally {
      setIsLoadingOldTicket(false)
    }
  }

  useEffect(() => {
    getOldTicketDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    oldTicket,
    isLoadingOldTicket
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { TicketModelCostumer, TicketDataFollowup } from 'entities'
import { WrapperFollowupList } from './styles'
import AdminModal from 'components/Modal/ModalContent'
import { ToastContainer } from 'react-toastify'
import FollowupItem from './FollowupItem'

type Props = {
  ticket: TicketModelCostumer | null
  toggleModal: () => void
}

const TicketFollowupModal = ({ ticket, toggleModal }: Props) => {
  const handleCloseModal = () => {
    toggleModal()
  }

  return (
    <AdminModal
      title="ACOMPANHAMENTO DO CHAMADO"
      handleCloseModal={handleCloseModal}
      handleSubmit={null}
      isLoading={false}
    >
      <WrapperFollowupList>
        {ticket?.FollowUp?.map((data: TicketDataFollowup, index: number) => (
          <FollowupItem key={`${data.followup}+${index}`} followup={data} />
        ))}
      </WrapperFollowupList>
      <ToastContainer />
    </AdminModal>
  )
}

export default TicketFollowupModal

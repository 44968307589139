import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const ContainerService = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: -4.5rem;

  // Align with the header
  padding-right: 2rem;
  padding-left: 3rem;

  .service-content{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .service-cards {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5rem;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  ${breakQueriesMaxMin(1700, SizeBreakpoint.xxl)} {
    .service-cards {
      grid-template-columns: repeat(6, 1fr) !important;
      column-gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xxl, 1365)} {
    .service-cards {
      grid-template-columns: repeat(5, 1fr) !important;
      column-gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(1365, SizeBreakpoint.xl)} {
    .service-cards {
      grid-template-columns: repeat(4, 1fr) !important;
      
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, 1180)} {
    .service-cards {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }

  ${breakQueriesMaxMin(1180, 830)} {
    .service-cards {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }

  ${breakQueriesMaxMin(830, SizeBreakpoint.sm)} {
    .service-cards {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    .service-cards {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
`

import { Breadcrumb, ContainerLoading, PageModel, TicketInfo } from 'components'
import { useGetOldTicketDetails } from 'hooks/useGetOldTicketDetails'
import { IDatabaseFilter } from 'hooks/useGetOldTickets'
import { ContainerDetailsTicket } from 'pages/TicketDetails/styles'
import { useParams } from 'react-router-dom'
import OldTicketInfo from 'components/OldTicketDetails/OldTicketInfo'

const OldTicketDetails = () => {
  const { ticketId, base } = useParams()
  const { oldTicket, isLoadingOldTicket } = useGetOldTicketDetails(ticketId as string, base as IDatabaseFilter)
  return (
    <PageModel title='Detalhes do Chamado'>
      <ContainerDetailsTicket>
        <Breadcrumb titlePage="VISUALIZAR SOLICITAÇÃO" goToHome={true} />
        <div className="details-ticket-content">
          {isLoadingOldTicket ? <ContainerLoading /> : <OldTicketInfo ticket={oldTicket} base={base as IDatabaseFilter} />}
        </div>
      </ContainerDetailsTicket>
    </PageModel>
  )
}

export default OldTicketDetails

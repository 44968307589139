import { IoMdWarning } from 'react-icons/io'
import { NotificationBox } from './styles'

type Props = {
  message: string
}

const Notification = ({ message }: Props) => {
  return (
    <NotificationBox>
      <div className="icon">
        <IoMdWarning />
      </div>
      <div className="message">
        <p>{ message}</p>
      </div>
    </NotificationBox>
  )
}

export default Notification

import Carousel from 'nuka-carousel'
import { ContainerNewsCarousel, ContainerTitle } from './styles'
// import newsSebrae from '../../assets/AVISO_cropped_sb.png'
import { useGetNews } from 'hooks'
import { ClipLoader } from 'react-spinners'
import { useEffect } from 'react'

const NewsCarousel = () => {
  const { news, isLoading, getNews } = useGetNews()

  useEffect(() => {
    getNews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ContainerNewsCarousel>
      <ContainerTitle>
        {'DESTAQUES'}
        <span />
      </ContainerTitle>
      <div className="newList-content">
        {isLoading && (
          <div className="list-loading">
            <ClipLoader
              color="#002356"
              size={45}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
        {!isLoading && news.length === 0 && (
          <div className="list-empty-message">
            <p>Nenhum destaque encontrado!</p>
          </div>
        )}
        {!isLoading && news.length > 0 && (
          <Carousel
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            autoplay={true}
            autoplayInterval={10000}
            wrapAround={true}
          >
            {news.map((item) => (
              <div key={item.id} className="carousel-item">
                <img
                  src={item.imageUrlData}
                  alt="preview da imagen"
                  className="news-item__img"
                  loading="lazy"
                />
                <div className="text" dangerouslySetInnerHTML={{ __html: item.description }}></div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </ContainerNewsCarousel>
  )
}

// const newsData = [
//   {
//     id: 1,
//     image: newsSebrae,
//     text: 'Sejam bem-vindos ao novo portal de atendimento SEBRAE-SP.'
//   }
// ]
// const NewsCarousel = () => {
//   return (
//     <ContainerNewsCarousel>
//       <ContainerTitle>
//         {'INFORMATIVOS'}
//         <span />
//       </ContainerTitle>
//       <div className="newList-content">
//         <Carousel
//           renderCenterLeftControls={null}
//           renderCenterRightControls={null}
//           autoplay={true}
//           autoplayInterval={10000}
//           wrapAround={true}
//         >
//           {newsData.map((item) => (
//             <div key={item.id} className="carousel-item">
//               <img src={item.image} alt={item.text} />
//               <p className='text'>{item.text}</p>
//             </div>
//           ))}

//         </Carousel>
//       </div>
//     </ContainerNewsCarousel>
//   )
// }

export default NewsCarousel

import { useState } from 'react'
import { toast } from 'react-toastify'
import { ModalContent } from 'components'
import { CardModel } from '../../entities'
import { updateCardListOrder } from 'services/api'
import { ModalBodyConfirm } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  cardListSort: CardModel[]
}

const SortCardsModal = ({ toggleModal, updateData, cardListSort }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await updateCardListOrder(cardListSort)

      updateData()
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao reordenar os cards')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Alterar a ordem dos cards"
      handleCloseModal={toggleModal}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      btnText="Confirmar"
    >
      <ModalBodyConfirm>
        <p className="message">Deseja salvar a nova ordem de visualização dos cards? </p>
      </ModalBodyConfirm>
    </ModalContent>
  )
}

export default SortCardsModal

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AddArticleModal,
  AddButton,
  AdmLayout,
  DeleteArticleModal,
  EditArticleModal,
  Pagination,
  Spinner
} from 'components'
import { OptionSelect } from 'components/Select/Select'
import { getArticleList, getForms } from 'services/api'
import { ArticleModel, FormModel } from '../../../entities'

import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperArticleList,
  WrapperContent
} from '../styles'

export const ArticlesAdmin = () => {
  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const [articleList, setArticleList] = useState<ArticleModel[]>([])
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingFormsAssociated, seIsLoadingFormsAssociated] = useState(false)
  const [articleSelected, setArticleSelected] = useState<ArticleModel | null>(null)
  const [formOptions, setFormOptions] = useState<OptionSelect[]>([])
  const [formOptionsSelected, setFormOptionsSelected] = useState<OptionSelect[]>([])

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
    }
  }

  const getArticles = async (page = pagination.page) => {
    try {
      setIsLoading(true)
      const response = await getArticleList(page)
      if (!response) {
        throw new Error()
      }
      setArticleList(response.data)
      setPagination({
        page,
        next: response.paginate.next,
        prev: response.paginate.prev
      })
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao carregar as FAQs')
    } finally {
      setIsLoading(false)
    }
  }

  const getFormOptions = async () => {
    try {
      seIsLoadingFormsAssociated(true)
      const response = await getForms({ limit: 500 })

      if (response.data.length > 0) {
        const forms: OptionSelect[] = []
        response.data.forEach((el: FormModel) => {
          const label = `${el.title}`

          forms.push({
            value: { id: el.id as number, title: el.title },
            label
          })
        })
        if (isOpenEditModal) {
          getFormsAssociated(forms)
        }
        setFormOptions(forms)
      }
    } catch (error) {
      toast.error('Ocorreu um erro!')
    } finally {
      seIsLoadingFormsAssociated(false)
    }
  }

  const getFormsAssociated = (options: OptionSelect[]) => {
    const list: OptionSelect[] = []

    articleSelected?.formsAssociated?.forEach((el) => {
      const formSelected = options.find((opt: any) => opt.value.id === el.id)
      if (formSelected) {
        list.push(formSelected)
      }
    })
    setFormOptionsSelected(list)
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenEditModal && !isOpenDeleteModal) {
      getArticles()
    }
  }, [])

  useEffect(() => {
    if (isOpenAddModal) {
      getFormOptions()
    }
  }, [isOpenAddModal])

  useEffect(() => {
    if (isOpenEditModal) {
      getFormOptions()
    }
    if (!isOpenEditModal) {
      setFormOptionsSelected([])
    }
  }, [isOpenEditModal])

  return (
    <AdmLayout title="Administração de FAQs">
      <WrapperContent>
        <TitlePage>Faq</TitlePage>
        <AddButton text="Criar Faq" onClick={toggleAddModal} />
        <WrapperArticleList>
          <label>Faq cadastradas:</label>

          {isLoading && <Spinner />}
          {!isLoading && articleList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhuma FAQ encontrada!</p>
            </EmptyListMessage>
          )}
          {!isLoading && articleList.length > 0 && (
            <table className="article-list-table">
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Formulários associados</th>
                  <th>AÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {articleList.map((article) => (
                  <tr key={article.id}>
                    <td>{article.title}</td>
                    <td>
                      {article.formsAssociated.map((form) => (
                        <div key={form.id} className="row-form-title">
                          {form.title}
                        </div>
                      ))}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setArticleSelected(article)
                          toggleEditModal()
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        onClick={() => {
                          setArticleSelected(article)
                          toggleDeleteModal()
                        }}
                        title="Remover"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperArticleList>
        {!isLoading && articleList.length > 0 && (
          <Pagination
            currentPage={pagination.page + 1}
            onNextPage={() => {
              getArticles(pagination.page + 1)
            }}
            onPreviousPage={() => {
              getArticles(pagination.page - 1)
            }}
            hasNextPage={pagination.next}
            hasPreviousPage={pagination.prev}
          />
        )}
      </WrapperContent>
      <ModalWrapper ref={refAddModal} >
        {isOpenAddModal && (
          <AddArticleModal
            toggleModal={toggleAddModal}
            formOptions={formOptions}
            getArticles={() => {
              getArticles(0)
            }}
            isLoadingFormsAssociated={isLoadingFormsAssociated}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && articleSelected && (
          <EditArticleModal
            toggleModal={toggleEditModal}
            articleSelected={articleSelected}
            formOptions={formOptions}
            formOptionsSelected={formOptionsSelected}
            getArticles={() => {
              getArticles()
            }}
            isLoadingFormsAssociated={isLoadingFormsAssociated}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && articleSelected && (
          <DeleteArticleModal
            toggleModal={toggleDeleteModal}
            articleSelected={articleSelected}
            getArticles={() => {
              getArticles(0)
            }}
          />
        )}
      </ModalWrapper>
    </AdmLayout>
  )
}

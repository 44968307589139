/* eslint-disable @typescript-eslint/quotes */
import { Link } from 'react-router-dom'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { useGetColorFromTicketStatus, useGetTickets } from '../../hooks'

import { ContainerTickets, ContainerTitle, TicketStatus } from './styles'
import { MoonLoader } from 'react-spinners'

const TicketList = () => {
  const { ticketList, isLoadingTickets } = useGetTickets(4)
  const { getColor } = useGetColorFromTicketStatus()
  return (
    <ContainerTickets>
      <ContainerTitle>
        {'MINHAS SOLICITAÇÕES'}
        <span />
      </ContainerTitle>
      {isLoadingTickets && (
        <div className="loading">
          <MoonLoader size={20} color={'#123abc'} />
          <h4> Carregando os chamados</h4>
        </div>
      )}
      {!isLoadingTickets &&
        ticketList.length > 0 &&
        ticketList
          .map((ticket) => (
            <Link
              key={ticket.ticketID}
              to={`/ticket/detail/${ticket.ticketID}`}
              className="ticket-item"
            >
              <div className="ticket-item-info">
                <span>{`N° ${ticket.ticketID}`}</span>
                <span>{ticket.title}</span>
                {ticket.status.toUpperCase() === 'PROCESSANDO ATRIBUÍDO' ? (
                  <TicketStatus $statusColor={getColor(ticket.status)}>Em Andamento</TicketStatus>
                ) : (
                  <TicketStatus $statusColor={getColor(ticket.status)}>
                    {ticket.status}
                  </TicketStatus>
                )}
              </div>
              <div className="ticket-item-icon">
                <RiArrowDropRightLine />
              </div>
            </Link>
          ))
        }

      {!isLoadingTickets && ticketList.length === 0 && (
        <div>
          <span>Nenhum chamado encontrado!</span>
        </div>
      )}
    </ContainerTickets>
  )
}

export default TicketList

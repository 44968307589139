import { useState } from 'react'
import { toast } from 'react-toastify'
import { ModalContent } from 'components'
import { INews } from '../../entities'
import { deleteNews } from 'services/api'
import { deleteFilePublic } from 'services/fileManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalBodyDelete } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  newsSelected: INews
  getNews: () => void
}

const DeleteNewsModal = ({ toggleModal, newsSelected, getNews }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await deleteNews(newsSelected.id)
      if (!response) {
        throw new Error()
      }
      await deleteFilePublic(newsSelected.imageUrl)
      setIsLoading(false)
      getNews()
    } catch (error: any) {
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao remover o destaque')
    } finally {
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover destaque"
      handleCloseModal={toggleModal}
      handleSubmit={handleDelete}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#cf1f3f"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover este destaque?</p>
        </div>
        <div className="text" dangerouslySetInnerHTML={{ __html: newsSelected.description }} />
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteNewsModal

/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export function useGetColorFromTicketStatus() {
  const theme = useContext(ThemeContext)
  function getColor(status: string) {
    switch (status) {
      case 'Novo':
        return theme?.colors.gray._600
      case 'Processando Atribuído': // Em Andamento
        return theme?.colors.blue._200
      case 'Pendente':
        return theme?.colors.red._700
      case 'Solucionado':
        return theme?.colors.green._600
      case 'Fechado':
        return theme?.colors.black
      case 'Agendado':
        return theme?.colors.yellow._700
      default:
        return theme?.colors.gray._600
    }
  }

  return {
    getColor
  }
}

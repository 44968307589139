import { useState } from 'react'
import { toast } from 'react-toastify'
import { INews } from 'entities'
import { updateNewsListWeight, getNewsList } from 'services/api'
import defaultNewsImage from '../assets/AVISO_448x200.png'

export const useGetNews = () => {
  const [news, setNews] = useState<INews[]>([])
  const [newsActive, setNewsActive] = useState<INews[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const setOrderNewsList = async (newListReordered: INews[]) => {
    if (newListReordered.length > 0) {
      setIsLoading(true)
      const newList: Array<{ id: string | number, weight: number }> = []
      newListReordered.forEach((item, index) => newList.push({ id: item.id, weight: index }))
      try {
        const response = await updateNewsListWeight(newList)
        if (!response) {
          throw new Error()
        }
        getNews()
      } catch (error: any) {
        if (error?.response?.status === 401) {
          toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
          setTimeout(() => {
            sessionStorage.clear()
            localStorage.clear()
            window.location.href = '/login'
          }, 2000)
          return
        }
        toast.error('Ops 😥, ocorreu um erro ao reordenar os destques')
      } finally {
        setIsLoading(false)
      }
    }
  }

  const getNews = async () => {
    setIsLoading(true)
    try {
      const response = await getNewsList()
      if (!response) {
        throw new Error()
      }
      const newsListContent: INews[] = []
      response.forEach((news) => {
        newsListContent.push({
          id: news.id,
          weight: news.weight,
          description: news.description,
          imageUrl: news.url_picture,
          imageUrlData: news.url_picture ?? defaultNewsImage,
          isEnable: news.isEnable
        })
      })
      const filterActiveNews = newsListContent.filter((news) => news.isEnable)
      setNewsActive(filterActiveNews)
      setNews(newsListContent)
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao carregar os destaques')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    news,
    isLoading,
    setOrderNewsList,
    getNews,
    newsActive
  }
}

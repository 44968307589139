/* eslint-disable @typescript-eslint/space-before-function-paren */
import { CardModel } from 'entities/cardModels'

type ItemSelectedProps = {
  list: [] | CardModel[]
  idL1: string | number | null | undefined
  idL2?: string | number | null | undefined
  id: string | number
}

export function getItemSelected({ list, idL1, idL2, id }: ItemSelectedProps) {
  if (idL2) {
    const cardSelectedL1 = list.find((item) => item.id.toString() === idL1)
    const cardSelectedL2 = cardSelectedL1?.children.find((item) => item.id.toString() === idL2)
    const cardSelected = cardSelectedL2?.children.find((item) => item.id.toString() === id)
    return cardSelected
  }
  if (idL1) {
    const cardSelectedL1 = list.find((item) => item.id.toString() === idL1)
    const cardSelected = cardSelectedL1?.children.find((item) => item.id.toString() === id)
    return cardSelected
  }
  const cardSelected = list.find((item) => item.id.toString() === id)

  if (!cardSelected) return null
  return cardSelected
}

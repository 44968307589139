import styled from 'styled-components'

export const ContainerNotification = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-top: -4.5rem;

  // Align with the header
  padding-right: 4rem;
  padding-left: 3rem;

  .content{
    width: 100%;
    display: flex;
    justify-content: center;
  }

`

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CategoryInfoButton, CategoryModal, ModalContent } from 'components'
import AttributeFieldList from '../TicketForms/Fields/AttributeFields/AttributeFieldList'
import { AttributeModel, CategoryStatus, CategoryType } from '../../entities'
import { ModalWrapper } from 'pages/Admin/styles'

type Props = {
  toggleModal: () => void
  optIndex: number
  option: any
  optionsSelectElementFieldName: any
  hideCategory: boolean
}

const EditOptionContentModal = ({
  toggleModal,
  optIndex,
  option,
  optionsSelectElementFieldName,
  hideCategory
}: Props) => {
  const { getValues } = useFormContext()
  const refCategoryModal = useRef<HTMLDialogElement>(null)
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)

  const toggleCategoryModal = () => {
    if (refCategoryModal.current) {
      if (isOpenCategoryModal) {
        setIsOpenCategoryModal(false)
        refCategoryModal?.current.close()
        return
      }
      setIsOpenCategoryModal(true)
      refCategoryModal?.current.showModal()
    }
  }

  return (
    <ModalContent
      title={`Editar campos da opção: ${option?.value ?? ''} `}
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
      btn2Text="Salvar"
      handleOpenModal={toggleModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1.25rem' }}>
        {!hideCategory && (
          <CategoryInfoButton
            categorySelected={{
              categoryId: getValues(
                `${optionsSelectElementFieldName}.${optIndex}.categoryId`
              ) as string,
              type: getValues(`${optionsSelectElementFieldName}.${optIndex}.type`) as CategoryType,
              group: getValues(`${optionsSelectElementFieldName}.${optIndex}.group`) as string,
              name: getValues(
                `${optionsSelectElementFieldName}.${optIndex}.categoryName`
              ) as string,
              status: getValues(
                `${optionsSelectElementFieldName}.${optIndex}.openingStatus`
              ) as CategoryStatus
            }}
            toggleCategoryModal={toggleCategoryModal}
            labelBtn="Adicionar categoria"
          />
        )}
        <AttributeFieldList<AttributeModel>
          attributeFieldName={`${optionsSelectElementFieldName}.${optIndex}.attributes`}
        />
      </div>
      <ModalWrapper ref={refCategoryModal} $width="70%">
        {isOpenCategoryModal && (
          <CategoryModal
            toggleModal={toggleCategoryModal}
            fieldName={`${optionsSelectElementFieldName}`}
            optIndex={optIndex}
          />
        )}
      </ModalWrapper>
    </ModalContent>
  )
}

export default EditOptionContentModal

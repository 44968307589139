import { AuthContext } from 'contexts/auth'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { formatOldTicketListData } from './utils/formatTicket'
import { OldTicketI, TicketI } from 'entities'
import { getOldTickets } from 'services/connector'
import { IPagination } from 'components/ConsultOldTicketList/OldTicketListAll'

export type IDatabaseFilter = 'outdated' | 'rdms'
type GetDataProps = {
  starDate: number | null
  endDate: number | null
  requester: string
  base: IDatabaseFilter
}
export const useGetOldTickets = ({ starDate: dateStart = null, endDate: dateEnd = null, requester = '', base = 'rdms' }: GetDataProps) => {
  const [isLoadingOldTickets, setIsLoadingOldTickets] = useState(false)
  const [oldTicketList, setOldTicketList] = useState<[] | OldTicketI[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    next: true,
    prev: false,
    page: 0
  })
  const { logout } = useContext(AuthContext)
  const limit = 12
  const getTickets = async () => {
    setIsLoadingOldTickets(true)
    try {
      if (!localStorage.getItem('user')) {
        toast.error(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
        setTimeout(() => {
          logout()
        }, 2000)
        return
      }

      const response = await getOldTickets(requester, dateStart, dateEnd, limit, pagination.page, base)

      const ticketListFormatted = formatOldTicketListData(response.tickets)
      setOldTicketList(ticketListFormatted)
      setPagination({
        page: pagination.page,
        prev: response.paginate.prev,
        next: response.paginate.next
      })

      if (!response) {
        setOldTicketList([])
      }
    } catch (error: any) {
      console.log(error)

      toast.error('Ops 😥, ocorreu um erro ao recuperar os chamados ')
    } finally {
      setIsLoadingOldTickets(false)
    }
  }
  useEffect(() => {
    getTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requester, dateStart, dateEnd, pagination.page, base])
  return {
    isLoadingOldTickets,
    oldTicketList,
    pagination,
    setPagination
  }
}

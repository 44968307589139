import { TbPhoneCall } from 'react-icons/tb'

import { ContainerContacts, ContainerTitle } from './styles'
import logoTeams from '../../assets/logoTeams.png'
// import { VscHubot } from 'react-icons/vsc'

const Contacts = () => {
  return (
    <ContainerContacts>
      <ContainerTitle>
        {'FALE CONOSCO'}
        <span />
      </ContainerTitle>

      <div className="contacts-content">
        <div className="title-desk">
          <p>
            Para service desk, entre em contato via bot Teams para agilizar sua abertura de chamados
            ou através do telefone para assistência direta.
          </p>
        </div>
        <div className="wrapper-channels">
          <div className="contacts-teams">
            <img src={logoTeams} alt="sebrae" width={'60rem'} />
            <span>
              <a
                target="_blank"
                href="https://teams.microsoft.com/l/app/971611a8-74d0-4a8d-a381-2f843455ad7b?source=app-details-dialog"
                rel="noreferrer"
              >
                Fale com Service Desk
              </a>
            </span>
          </div>
          <div className="contacts-phone">
            <span>
              Ligação interna via webex:
            </span>
            <span>
              <TbPhoneCall />
              (4) 4632
            </span>
            <span>
              <TbPhoneCall />
              (4) 4631
            </span>
          </div>
          <div className="contacts-phone">
            <span>
              Ligação externa:
            </span>
            <span>
              <TbPhoneCall />
              (11) 3177 4632
            </span>

            <span>
              <TbPhoneCall />
              (11) 3177 4631
            </span>
          </div>
        </div>
      </div>
    </ContainerContacts>
  )
}

export default Contacts

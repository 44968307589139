import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TicketStatus } from 'components/Home/styles'
import FollowupItem from 'components/TicketDetails/FollowupItem'
import { BottomContent, BtnViewMore, Container, Description, Information, MiddleContent, ModalWrapper, Title, TopContent } from 'components/TicketDetails/styles'
import TicketFollowupModal from 'components/TicketDetails/TicketFollowupModal'
import { TicketModelCostumer } from 'entities'
import { useGetColorFromTicketStatus } from 'hooks'
import { IDatabaseFilter } from 'hooks/useGetOldTickets'
import { useRef, useState, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import OldTicketFollowupModal from './OldTicketFollowupModal'
import OldFollowupItem from './OldFollowupItem'

type Props = {
  ticket: TicketModelCostumer | null
  base: IDatabaseFilter
}

const OldTicketInfo = ({ ticket, base }: Props) => {
  const { getColor } = useGetColorFromTicketStatus()
  const refTicketModal = useRef<HTMLDialogElement>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const toggleTicketModal = () => {
    if (refTicketModal.current) {
      if (isOpenModal) {
        setIsOpenModal(false)
        refTicketModal?.current.close()
        return
      }
      setIsOpenModal(true)
      refTicketModal?.current.showModal()
    }
  }

  return (
    <Container>
      <TopContent>
        <Title>
          <div>
            {base === 'outdated' ? (
              <>
                <span className="title">Início&nbsp;</span>
                {ticket?.category.map((category) => (
                  <span key={category} className="title">
                    ▶&nbsp;{category}&nbsp;
                  </span>
                ))}
              </>
            ) : ''}
          </div>
          <div className="id">
            <span>Chamado</span>
            <span>{ticket?.id}</span>
          </div>
        </Title>
        <span className="description">{ticket?.title}</span>
        <div className="ticket-info">
          <Information>
            <div className="status">
              <span>Status:</span>
              {ticket?.status.toUpperCase() === 'PROCESSANDO ATRIBUÍDO' ? (
                <TicketStatus $statusColor={getColor(ticket.status)}>Em Andamento</TicketStatus>
              ) : (
                <TicketStatus $statusColor={getColor(ticket?.status as string)}>
                  {ticket?.status}
                </TicketStatus>
              )}
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Data de abertura:</span>
              <span>{ticket?.date_modify}</span>
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Fila de atendimento</span>
              <span>{ticket?.group.replace(/_.*/, '')}</span>
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Prazo para solução:</span>
              <span>{ticket?.date_resolve}</span>
            </div>
          </Information>
        </div>
      </TopContent>
      <MiddleContent>
        <span className="description">DESCRIÇÃO DO CHAMADO</span>
        <Description>
          {ticket?.description.map((description, index) => (
            <span key={`${description}+${index}`} className="response">
              {description}
            </span>
          ))}
        </Description>
      </MiddleContent>
      <BottomContent>
        <div className="comment">
          <span className="accompaniment">ACOMPANHAMENTO DO CHAMADO</span>

          {ticket?.LatestFollowup ? (
            <OldFollowupItem base={base} followup={ticket.LatestFollowup} />
          ) : (
            <span className="text">Este chamado ainda não tem acompanhamento.</span>
          )}

          {ticket?.FollowUp && ticket?.FollowUp?.length > 1 && (
            <BtnViewMore>
              <button onClick={toggleTicketModal}>
                <span>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                Ver mais
              </button>
            </BtnViewMore>
          )}

          <ModalWrapper ref={refTicketModal} $width="70%">
            {isOpenModal && <OldTicketFollowupModal base={base} ticket={ticket} toggleModal={toggleTicketModal} />}
          </ModalWrapper>
        </div>
      </BottomContent>
    </Container>
  )
}

export default memo(OldTicketInfo)

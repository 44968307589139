import Select, {
  MenuPlacement,
  MultiValue,
  OptionProps,
  SingleValue,
  components
} from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setDefaultStyles } from './utils'

const CustomOption = (props: OptionProps<any, boolean, any>) => {
  return (
    <components.Option {...props}>
      {props.data.icon ? (
        <FontAwesomeIcon icon={props.data.icon} style={{ marginRight: '0.5rem' }} size="sm" />
      ) : null}
      {props.data.label}
    </components.Option>
  )
}

export type OptionSelect = {
  label: string
  value: string | number | { id: number, title: string } | any
  // value: string | number | FormAssociatedModel
  icon?: string
}

type Props = {
  onChange: (e: SingleValue<OptionSelect> | MultiValue<OptionSelect>) => void
  optionValues: OptionSelect[]
  isMulti?: boolean
  styles?: object | null
  noOptMessage?: string
  defaultValue?: OptionSelect[] | OptionSelect
  menuPlacement?: MenuPlacement
  placeholder?: string
  closeMenuOnSelect?: boolean
  isClearable?: boolean
}

const SelectComponent = ({
  onChange,
  optionValues,
  isMulti = false,
  styles = null,
  noOptMessage = '',
  defaultValue = [],
  menuPlacement = 'auto',
  placeholder = '',
  closeMenuOnSelect = true,
  isClearable = false
}: Props) => {
  return (
    <Select
      classNamePrefix="select"
      className={isMulti ? 'basic-multi-select' : 'basic-single'}
      styles={styles ?? setDefaultStyles(isMulti)}
      onChange={onChange}
      options={optionValues}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder={placeholder || 'Selecione uma opção'}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: '0.5rem',
        colors: {
          ...theme.colors,
          primary: '#d2d2d2',
          neutral80: '#77787b',
          primary25: '#def4fd ',
          neutral50: '#a8a9ad',
          neutral20: '#a8a9ad'
        }
      })}
      menuPlacement={menuPlacement}
      noOptionsMessage={() => noOptMessage ?? 'Sem opções disponível'}
      defaultValue={defaultValue}
      components={{ Option: CustomOption }}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  )
}

export default SelectComponent

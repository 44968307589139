import { ButtonHTMLAttributes } from 'react'
import { BtnAdd } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
}

const AddButton = ({ text, ...props }: Props) => {
  return (
    <BtnAdd className="content-btn-add-admin" {...props}>
      <p>+</p>
      <p>{text}</p>
    </BtnAdd>
  )
}

export default AddButton

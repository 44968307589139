import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const Container = styled.div<{ $isHomePage?: boolean, $isOverflowY?: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray._200};
  overflow-x: hidden !important;

  ${breakQueriesMaxMin(SizeBreakpoint.md, 641)} {
    height: ${(props) => (props.$isHomePage ? '100%' : '100vh')};
  }
  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    height: ${(props) =>
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    props.$isHomePage || props.$isOverflowY ? '100%' : '100vh'};
  }

`

import { useState } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import ReactSwitch from 'react-switch'
import { SingleValue } from 'react-select'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrip } from '@fortawesome/free-solid-svg-icons'

import { Select } from 'components'
import { OptionSelect } from 'components/Select/Select'
import { AttributeModel, InputType } from '../../../../entities'
import { renderContent, typeFieldOptions } from './utils'

import { ContainerField, ErrorMessage, customSelectStyles } from './styles'
import { IAttributeProps } from './AttributeFieldList'

type Field =
  | 'question'
  | 'type'
  | 'positionOrder'
  | 'isHiddenToVip'
  | 'isVisibleToDescription'
  | 'required'
  | 'propertyId'
  | any

type Props = {
  attribute: AttributeModel | IAttributeProps
  question: Field
  type: Field
  positionOrder: Field
  isHiddenToVip: Field
  isVisibleToDescription: Field
  required: Field
  isMoved?: boolean
  id?: string | number
  optionsSelectElementFieldName: Field
  firstLevel?: boolean
  propertyId?: Field | null
}

const AttributeField = <T extends FieldValues>({
  attribute,
  question,
  type,
  positionOrder,
  isHiddenToVip,
  isVisibleToDescription,
  required,
  isMoved = true,
  id = '',
  optionsSelectElementFieldName,
  firstLevel = false, // TO AVOID INFINITY CYCLE OF SELECT <-> SELECT
  propertyId = null
}: Props) => {
  const {
    register,
    formState: { errors },
    control,
    getValues
  } = useFormContext<T>()

  const [fieldType, setFieldType] = useState<InputType>(attribute?.type as InputType)
  const defaultOptionType = typeFieldOptions.find((opt) => opt.value === attribute.type)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  return (
    <ContainerField ref={setNodeRef} style={style}>
      {isMoved && (
        <div className="btn-move">
          <button type="button" title="Mover" {...attributes} {...listeners}>
            <FontAwesomeIcon icon={faGrip} />
          </button>
        </div>
      )}
      <div className="header">
        <div className="section-question">
          <input
            type="text"
            placeholder="Digite aqui a pergunta"
            {...register(question, { required: '* Campo obrigatório' })}
          />
          {errors?.question && <ErrorMessage>{errors?.question?.message?.toString()}</ErrorMessage>}
        </div>

        <Controller
          name={type}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <div className="section-type">
              <Select
                defaultValue={defaultOptionType}
                optionValues={typeFieldOptions}
                onChange={(e) => {
                  setFieldType((e as SingleValue<OptionSelect>)?.value as InputType)
                  onChange((e as SingleValue<OptionSelect>)?.value)
                }}
                styles={customSelectStyles}
                menuPlacement="bottom"
              />
            </div>
          )}
        />
      </div>

      <div className="content">
        {renderContent({ type: fieldType, optionsSelectElementFieldName, firstLevel })}
      </div>

      <div className="footer">
        {propertyId && (
          <div className="section-text">
            <input type="text" {...register(propertyId)} />
            <label htmlFor="propertyId">Id da propriedade</label>
          </div>
        )}

        <div className="section-number">
          <input type="number" {...register(positionOrder)} />
          <label htmlFor="positionOrder">Ordem de exibição</label>
        </div>

        <div className="section-checkbox">
          <input type="checkbox" {...register(isHiddenToVip)} />
          <label htmlFor="isHiddenToVip">Oculto para usuário Vip</label>
        </div>

        <Controller
          name={isVisibleToDescription}
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange } }) => (
            <div className="section-checkbox">
              <input
                type="checkbox"
                defaultChecked={!attribute.isVisibleToDescription}
                onChange={(e) => {
                  onChange(!e.target.checked)
                }}
              />
              <label htmlFor="isVisibleToDescription">Ocultar na descrição</label>
            </div>
          )}
        />
        <Controller
          name={required}
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange } }) => (
            <div className="section-switch">
              <ReactSwitch
                onChange={(e) => {
                  onChange(e)
                }}
                checked={getValues(required) ?? true}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={16}
                width={48}
                className="react-switch"
                id="material-switch"
              />
              <label htmlFor="required">Campo obrigatório</label>
            </div>
          )}
        />
      </div>
    </ContainerField>
  )
}

export default AttributeField

import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { ErrorMessage, FormInputSearch } from './styles'
import { AttributeModel, UserModel } from 'entities'
import { Controller, useFormContext } from 'react-hook-form'
import { getManagerList, getUsersByName } from 'services/connector'
import { toast } from 'react-toastify'

type Props = {
  attribute: AttributeModel
}

const ManagerField = ({ attribute }: Props) => {
  const [userList, setUserList] = useState<UserModel[]>([])
  const [userSelected, setUserSelected] = useState<string | null>(null)
  const [userNameTyped, setUserNameTyped] = useState('')

  const validations = {
    required: {
      value: attribute.required ?? false,
      message: '* Campo obrigatório'
    }
  }

  const {
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors }
  } = useFormContext()

  const handleSearchUser = async (e: ChangeEvent<HTMLInputElement>) => {
    setUserNameTyped(e.target.value)
    try {
      if (e.target.value === '') {
        setUserSelected(null)
        return
      }
      const response = await getUsersByName(e.target.value)
      if (!response) {
        throw new Error()
      }
      setUserList(response)
    } catch (error) {
      setUserList([])
      toast.error('Ops 😥, ocorreu um erro ao procurar o usuário ')
    }
  }

  const handleUserSelected = (user: UserModel) => {
    clearErrors(attribute.id.toString())
    setUserSelected(user.user_full_name)
    setValue(attribute.id.toString(), user.user_login)
    setUserList([])
    setUserNameTyped('')
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      setUserSelected(null)
      setUserNameTyped('')
    }
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={validations}
      render={({ field: { onChange } }) => (
        <FormInputSearch>
          <div className="input-search">
            <label htmlFor={attribute.id.toString()}>
              {attribute.question}
            </label>
            <input
              type="search"
              name={attribute.id.toString()}
              autoComplete="off"
              placeholder="Escreva o nome completo"
              value={userSelected ?? userNameTyped}
              onChange={(e) => {
                handleSearchUser(e)
              }}
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
            />
          </div>
          {userList.length > 0 && (
            <div className="input-search-list">
              <ul>
                {userList.map((user) => (
                  <li
                    key={user.user_id}
                    onClick={() => {
                      handleUserSelected(user)
                    }}
                  >
                    {user?.user_full_name.toUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {userList.length === 0 && userNameTyped && (
            <div className="input-search-list">
              <div className="input-search-item">Nenhum usuário encontrado</div>
            </div>
          )}
          {errors[attribute.id.toString()] && (
            <ErrorMessage>
              {errors[attribute.id.toString()]?.message?.toString()}
            </ErrorMessage>
          )}
        </FormInputSearch>
      )}
    />
  )
}

export default ManagerField

import styled from 'styled-components'

export const ContainerSearchInput = styled.div`
  position: relative;
  width: 50%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray._500};
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.white};

  &:focus-within {
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue._600};
  }

  @media (max-width: 880px) {
    margin-left: auto;
    width: 80%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  .search-icon, .clear-icon {
    width: 1.35rem;
    height: 1.35rem;
    color: ${({ theme }) => theme.colors.blue._600};
    z-index: 10;
    padding: 0.5rem;
    margin-left: auto;
    transition: all 0.2s;
    cursor: pointer;
    &:hover {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .clear-icon {
    color: ${({ theme }) => theme.colors.red._600};
  }

  .loading {
    z-index: 10;
    margin-left: auto;
    margin-bottom: 3rem;
  }

  .search-content {
    display: flex;
    flex-direction: column;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 2rem;
    line-height: 1.5rem;
    outline: 0;
    border: 0;
    font-size: 1.5rem;
    border-radius: 1.25rem;
    padding: 0 1rem;
    margin: 0;
    color: ${({ theme }) => theme.colors.gray._500};
    display: block;

    &::placeholder {
      font-size: 1.25rem;
      font-style: italic;
    }

    @media (max-width: 1080px) {
      &::placeholder {
        font-size: 1rem;
      }
    }
  }
`

export const InputSearchList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2.5rem;
  display: block;
  width: 100% !important;
  z-index: 100;
  height: fit-content;
  max-height: 14rem;
  overflow-y: scroll;
  color: ${({ theme }) => theme.colors.gray._500};
  border: 1px solid ${({ theme }) => theme.colors.gray._400};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0rem 1rem;
  background-clip: padding-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-box-shadow: 5px 6px 15px 5px #00000029;
  box-shadow: 5px 6px 15px 5px #00000029;

  @media (max-width: 768px) {
    width: 80%;
  }
  ul {
    list-style-type: none;

    li {
      margin-top: 0.5rem;
      cursor: pointer;
      &:hover {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.blue._600};
      }
    }
  }
`

import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const ContainerFaq = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  width: 100%;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 0.5rem;
`

export const ContainerAccordion = styled.div<{ $isAccordionOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  .accordion-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray._400};
    border-radius: ${(props) => (props.$isAccordionOpen ? '5px 5px 0 0' : '5px')};
    font-size: 1.1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue._400};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray._300};
    }

    svg {
      width: 1.35rem;
      min-width: 1.35rem;
      height: 1.35rem;
      min-height: 1.35rem;
      color: ${({ theme }) => theme.colors.blue._800};
    }
  }

  .accordion-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    padding: 1.15rem;
    width: 100%;
    gap: 0.75rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.gray._500};
    border: 1px solid ${({ theme }) => theme.colors.gray._400};
    border-radius: ${(props) => (props.$isAccordionOpen ? '0 0 5px 5px' : '50x')};
    text-align: justify;

    .content {
      width: 100%;
    }

    img {
      max-width: 55rem;
    }

    ul,
    ol {
      margin-left: 2rem;
    }

    // Class from react-quill editor
    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-left {
      text-align: left;
    }

    .accordion-content-feedback {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;

      p {
        color: ${({ theme }) => theme.colors.blue._800};
        font-weight: bold;
      }

      div {
        display: flex;
        gap: 1rem;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3rem;
          background-color: ${({ theme }) => theme.colors.white};
          border-radius: 0.5rem;
          border: 2px solid ${({ theme }) => theme.colors.gray._400};
          font-size: 0.85rem;
          font-weight: bold;
          line-height: 1.5;
          text-transform: uppercase;
          color: ${({ theme }) => theme.colors.blue._400};
          transition: 0.2s;
          cursor: pointer;

          &:hover {
            background-color: ${({ theme }) => theme.colors.blue._600};
            color: ${({ theme }) => theme.colors.white};
          }

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, 800)} {
    .accordion-content {
      img {
        max-width: 40rem;
      }
    }
  }

  ${breakQueriesMaxMin(800, SizeBreakpoint.md)} {
    .accordion-content {
      img {
        max-width: 35rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    .accordion-content {
      img {
        max-width: 32rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, 500)} {
    .accordion-content {
      img {
        max-width: 22rem;
      }
    }
  }

  ${breakQueriesMaxMin(500, SizeBreakpoint.xsm)} {
    .accordion-content {
      img {
        max-width: 13rem;
      }
    }
  }
`

export const Modal = styled.dialog`
  display: flex;
  flex-direction: column;
  width: 85%;
  padding: 2.5rem 2rem;
  gap: 1.25rem;
  color: ${({ theme }) => theme.colors.blue._800};

  .modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-weight: bold;
    font-size: 1.15rem;

    b {
      color: ${({ theme }) => theme.colors.blue._400};
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 1rem;

    p {
      text-align: left !important;
      font-weight: bold;
    }

    div {
      display: flex;
      gap: 1.5rem;
      button:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.3rem;
        padding: 0 0.75rem;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 0.5rem;
        border: 2px solid ${({ theme }) => theme.colors.blue._500};
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.blue._500};
        transition: 0.2s;
        cursor: pointer;

        :hover {
          background-color: ${({ theme }) => theme.colors.blue._600};
          color: ${({ theme }) => theme.colors.white};
        }

        :disabled {
          cursor: not-allowed;
        }
      }

      button:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.3rem;
        padding: 0 0.75rem;
        background-color: ${({ theme }) => theme.colors.blue._500};
        border-radius: 0.5rem;
        border: none;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.white};
        transition: 0.2s;
        cursor: pointer;

        :hover {
          background-color: ${({ theme }) => theme.colors.blue._600};
        }

        :disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    .modal-header{
      font-size: 1.1rem;
    }
    .modal-footer {
      div {
        button {
          font-size: 0.75rem !important;
        }
      }
    }
  }
`

export const EditorContainer = styled.div`
  height: 100%;

  .editor {
    height: 50%;
  }

  .result {
    border: 1px solid black;
    margin: 20px;
    min-height: 200px;
    padding: 10px;

    ul > li {
      margin-left: 20px;
    }

    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-left {
      text-align: left;
    }
  }
`

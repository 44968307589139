export const setDefaultStyles = (isMulti: boolean) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      boxSizing: 'border-box',
      height: 'fit-content',
      minHeight: '2.3rem',
      width: '20rem',
      minWidth: 'fit-content',
      padding: '0 0.5rem',
      border: state.isFocused ? '1px solid #707070' : '1px solid #d2d2d2',
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: '0.5rem',
      backgroundColor: state.isFocused ? '#f1f1f1' : '#fff',
      color: '#707070',
      borderColor: state.isSelected && 'red'
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      minHeight: '2.3rem'
    }),
    input: (provided: any) => ({
      ...provided,
      margin: '0px',
      padding: 0
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      padding: '0',
      minHeight: '2.3rem'
    }),

    indicatorSeparator: () => ({
      display: 'none'
    }),

    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: '0'
    }),

    menu: (provided: any) => ({
      ...provided,
      color: '#707070',
      width: '20rem',
      minWidth: 'fit-content'
    }),
    //* *********************************** */
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: isMulti && '#69c9fc',
        borderRadius: '0.625rem',
        padding: '0 0.25rem'
      }
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: isMulti && '#232323' // 'white'
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: isMulti && '#232323', // 'white',
      ':hover': {
        color: isMulti && 'white'
      }
    })
    //* *********************************** */
  }
  return customStyles
}

import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  height: fit-content !important;
  padding: 1rem 0 1rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  // z-index: 999;
  border-radius: 1rem;
  gap: 1rem;

  .description {
    text-align: left;
    font: normal normal bold 18px/25px Montserrat;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.blue._800};
    opacity: 1;
    text-transform: uppercase;
  }

  .ticket-info-btn {
      margin-right: 1.5rem;
      padding: 0.5rem 0.875rem;
      border: none;
      /* height: 2.5rem; */
      border-radius: 0.5rem;
      background-color: ${({ theme }) => theme.colors.blue._700};
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;

      &:hover{
        opacity: .9;
      }
    }

  .ticket-info {
    display: flex;
    justify-content: space-between;
    align-items: center;


  }

  @media (max-width: 1536px) {
    .description {
      font: normal normal bold 18px/25px Montserrat;
    }
  }

  @media (max-width: 1380px) {
    .description {
      font: normal normal bold 16px/25px Montserrat;
    }
  }

  @media (max-width: 1280px) {
    .description {
      font: normal normal bold 16px/25px Montserrat;
    }
  }

  @media (max-width: 1024px) {
    .description {
      font: normal normal bold 15px/25px Montserrat;
    }
  }

  @media (max-width: 768px) {
    .description {
      font: normal normal bold 14px/25px Montserrat;
    }
    .ticket-info {
      .ticket-info-btn {
        font-size: 0.875rem;
      }
    }
  }

  @media (max-width: 640px) {
    height: 200px;

    .description {
      font: normal normal bold 14px/25px Montserrat;
    }
    .ticket-info {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      .ticket-info-btn {
        margin-top: 1rem;
        font-size: 0.875rem;
      }
    }
  }

  @media (max-width: 320px) {
    .description {
      font: normal normal bold 13px/25px Montserrat;
    }
  }
`
export const ModalWrapper = styled.dialog<{ $minHeight?: string, $width?: string }>`
  padding: 1.75rem 2.75rem;
  height: fit-content;
  width: ${(props) => props.$width ?? '50%'};
  min-height: ${(props) => props.$minHeight ?? 'fit-content'};

  @media (max-width: 640px) {
    width: 80%;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    text-align: left;
    font: normal normal bold 13px/24px Montserrat;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.gray._500};
    opacity: 1;
  }

  .id {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 9.375rem;
    height: 2.9rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    background: ${({ theme }) => theme.colors.gray._300} 0% 0% no-repeat padding-box;
    border-radius: 56px 0px 0px 56px;
    opacity: 1;



    span:nth-child(1) {
      text-align: left;
      font: normal normal normal 15px/15px Montserrat;
      letter-spacing: 0px;
      color: ${({ theme }) => theme.colors.gray._500};
    }

    span:nth-child(2) {
      text-align: left;
      font: normal normal bold 15px/20px Montserrat;
      letter-spacing: 0px;
      color: ${({ theme }) => theme.colors.gray._500};
    }
  }

  @media (max-width: 1280px) {
    .title {
      font: normal normal bold 12px/25px Montserrat;
    }
    .id {
      width: 8.125rem;

      span:nth-child(1) {
        font: normal normal normal 14px/15px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 11px/15px Montserrat;
      }
    }
  }

  @media (max-width: 640px) {
    .title {
      font: normal normal bold 10px/15px Montserrat;
    }
    .id {
      width: 6.25rem;

      span:nth-child(1) {
        font: normal normal normal 11px/15px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 10px/15px Montserrat;
      }
    }
  }

  @media (max-width: 320px) {
    .title {
      font: normal normal bold 11px/25px Montserrat;
    }
    .id {
      width: 6.25rem;

      span:nth-child(1) {
        font: normal normal normal 12px/15px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 10px/15px Montserrat;
      }
    }
  }
`

export const Information = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .line {
    display: flex;
    width: 2px;
    height: 3rem;
    border: 2px solid ${({ theme }) => theme.colors.blue._800};
    opacity: 1;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    span:nth-child(1) {
      text-align: left;
      font: normal normal normal 13px/17px Montserrat;
      letter-spacing: 0px;
      color: ${({ theme }) => theme.colors.gray._500};
      opacity: 1;
    }

    span:nth-child(2) {
      text-align: left;
      font: normal normal bold 16px/26px Montserrat;
      letter-spacing: 0px;
      text-transform: uppercase;
      opacity: 1;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    span:nth-child(1) {
      text-align: left;
      font: normal normal normal 13px/17px Montserrat;
      letter-spacing: 0px;
      color: ${({ theme }) => theme.colors.gray._500};
      opacity: 1;
    }

    span:nth-child(2) {
      text-align: left;
      font: normal normal bold 14px/26px Montserrat;
      letter-spacing: 0px;
      color: ${({ theme }) => theme.colors.blue._800};
      opacity: 1;
      text-transform: uppercase;
    }
  }

  @media (max-width: 1536px) {
    .line {
      height: 3rem;
    }
  }

  @media (max-width: 1380px) {
    .line {
      height: 2.8rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  @media (max-width: 1280px) {
    .line {
      height: 2.5rem;
    }
    .status {
      span:nth-child(1) {
        font: normal normal normal 12px/17px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 14px/26px Montserrat;
      }
    }
    .info {
      span:nth-child(1) {
        font: normal normal normal 12px/17px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 14px/26px Montserrat;
      }
    }
  }

  @media (max-width: 1024px) {
    .line {
      height: 2.375rem;
    }
    .status {
      span:nth-child(1) {
        font: normal normal normal 12px/17px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 12px/26px Montserrat;
      }
    }
    .info {
      span:nth-child(1) {
        font: normal normal normal 12px/17px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 12px/26px Montserrat;
      }
    }
  }

  @media (max-width: 768px) {
    .line {
      height: 2.2rem;
      margin-left: 0.7rem;
      margin-right: 0.7rem;
    }
    .status {
      span:nth-child(1) {
        font: normal normal normal 11px/17px Montserrat;
      }
    }
    .info {
      span:nth-child(1) {
        font: normal normal normal 11px/17px Montserrat;
      }
    }
  }

  @media (max-width: 640px) {
    .line:nth-of-type(4) {
      display: none;
    }

    .line {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  @media (max-width: 320px) {
    .line:nth-of-type(4) {
      display: none;
    }

    .line {
      margin-left: 0.7rem;
      margin-right: 0.7rem;
    }

    .status {
      span:nth-child(1) {
        font: normal normal normal 9px/17px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 10px/26px Montserrat;
      }
    }
    .info {
      span:nth-child(1) {
        font: normal normal normal 9px/17px Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 10px/26px Montserrat;
      }
    }
  }
`

export const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-height: 10rem;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;

  .description {
    text-align: left;
    font: normal normal bold 20px/37px Montserrat;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.blue._800};
    opacity: 1;
  }

  @media (max-width: 1536px) {
    .description {
      font: normal normal bold 18px/25px Montserrat;
    }
  }

  @media (max-width: 1380px) {
    .description {
      font: normal normal bold 16px/25px Montserrat;
    }
  }

  @media (max-width: 1280px) {
    .description {
      font: normal normal bold 16px/25px Montserrat;
    }
  }

  @media (max-width: 1024px) {
    .description {
      font: normal normal bold 15px/25px Montserrat;
    }
  }

  @media (max-width: 768px) {
    .description {
      font: normal normal bold 14px/25px Montserrat;
    }
  }

  @media (max-width: 640px) {
    .description {
      font: normal normal bold 14px/25px Montserrat;
    }
  }

  @media (max-width: 320px) {
    .description {
      font: normal normal bold 13px/25px Montserrat;
    }
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  margin-top: 0.75rem;

  .response {
    text-align: left;
    font: normal normal normal 15px/30px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
  }

  @media (max-width: 1536px) {
    .response {
      font: normal normal normal 15px/25px Montserrat;
    }
  }

  @media (max-width: 1280px) {
    .response {
      font: normal normal normal 14px/23px Montserrat;
    }
  }

  @media (max-width: 1024px) {
    .response {
      font: normal normal normal 13px/21px Montserrat;
    }
  }

  @media (max-width: 768px) {
    .response {
      font: normal normal normal 13px/20px Montserrat;
    }
  }

  @media (max-width: 640px) {
    .response {
      font: normal normal normal 12px/19px Montserrat;
    }
  }

  @media (max-width: 320px) {
    .response {
      font: normal normal normal 11px/18px Montserrat;
    }
  }
`

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
  min-height: 9rem;
  height: fit-content;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;

  .comment {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
  }

  .accompaniment {
    text-align: left;
    font: normal normal bold 20px/37px Montserrat;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.blue._800};
    opacity: 1;
  }

  .text {
    text-align: left;
    margin-top: 1rem;
    font: normal normal normal 15px/20px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
  }

  .data-accompaniment {
    margin-top: 0.5rem;

    svg {
      font-size: 7px;
      margin-right: 0.4rem;
    }

    .create-info {
      text-align: left;
      margin-right: 0.4rem;
      font: normal normal bold 14px/16px Montserrat;
      color: ${({ theme }) => theme.colors.gray._600};
      letter-spacing: 0px;
      opacity: 1;
    }

    .data-followup {
      font: normal normal bold 14px/16px Montserrat;
      margin-right: 0.6rem;
      opacity: 0.8;
      color: ${({ theme }) => theme.colors.blue._700};
    }
  }

  .followup-info {
    margin-top: 0.2rem;
    span {
      font: normal normal normal 14px/16px Montserrat;
    }
  }

  .view-more {
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      font: normal normal normal 15px/17px Montserrat;
      color: ${({ theme }) => theme.colors.blue._700};
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.8;
      }
      .icon-circlePlus {
        margin-top: 0.03rem;

        svg {
          font-size: 1rem;
          margin-right: 0.1rem;
        }
      }
    }
  }

  @media (max-width: 2788px) {
    .icon-circlePlus {
      svg {
        margin-top: -1rem;
      }
    }
  }

  @media (max-width: 2250px) {
    .icon-circlePlus {
      svg {
        margin-top: 0.02rem;
      }
    }
  }

  @media (max-width: 1536px) {
    .accompaniment {
      font: normal normal bold 18px/25px Montserrat;
    }
    .icon-circlePlus {
      svg {
        margin-top: -0.03rem;
      }
    }
  }

  @media (max-width: 1380px) {
    .accompaniment {
      font: normal normal bold 16px/25px Montserrat;
    }
    .text {
      font: normal normal normal 14px/23px Montserrat;
    }
  }

  @media (max-width: 1280px) {
    .accompaniment {
      font: normal normal bold 16px/25px Montserrat;
    }
    .text {
      font: normal normal normal 14px/21px Montserrat;
    }
  }

  @media (max-width: 1024px) {
    .accompaniment {
      font: normal normal bold 15px/25px Montserrat;
    }
    .text {
      font: normal normal normal 13px/21px Montserrat;
    }
  }

  @media (max-width: 768px) {
    .accompaniment {
      font: normal normal bold 14px/25px Montserrat;
    }
    .text {
      font: normal normal normal 13px/20px Montserrat;
    }
  }

  @media (max-width: 640px) {
    .accompaniment {
      font: normal normal bold 14px/25px Montserrat;
    }
    .text {
      font: normal normal normal 12px/19px Montserrat;
    }
  }

  @media (max-width: 320px) {
    .accompaniment {
      font: normal normal bold 13px/25px Montserrat;
    }
    .text {
      font: normal normal normal 11px/18px Montserrat;
    }
  }
`
export const ContainerAllComments = styled.form`
  height: 12rem;
  overflow-y: scroll;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    span {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.blue._800};
      text-transform: uppercase;
    }

    button {
      padding: 0.35rem 0.65rem;
      background-color: ${({ theme }) => theme.colors.gray._400};
      border: none;
      border-radius: 50%;
      cursor: pointer;
      transition-duration: 0.2s;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  .data-accompaniment {
    margin-top: 0.5rem;

    svg {
      font-size: 7px;
      margin-right: 0.4rem;
      color: ${({ theme }) => theme.colors.gray._600};
    }

    .create-info {
      text-align: left;
      margin-right: 0.4rem;
      font: normal normal bold 14px/16px Montserrat;
      color: ${({ theme }) => theme.colors.gray._600};
      letter-spacing: 0px;
      opacity: 1;
    }

    .data-followup {
      font: normal normal bold 14px/16px Montserrat;
      margin-right: 0.6rem;
      color: ${({ theme }) => theme.colors.blue._700};
      opacity: 0.8;
    }
  }
`

export const WrapperFollowup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-top: 1rem;
  width: 100%;
  height: fit-content;

  .followup-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .followup-info__date,
    .followup-info__name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    svg {
      font-size: 0.5rem;
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.colors.gray._600};
    }

    .create-info {
      text-align: left;
      font: normal normal bold 1rem Montserrat;
      color: ${({ theme }) => theme.colors.gray._600};
    }

    .data-followup {
      font: normal normal bold 1rem Montserrat;
      color: ${({ theme }) => theme.colors.blue._600};
    }
  }

  .followup-description {
    width: 90%;
    margin-left: 1.5rem;
    margin-top: 0.5rem;

    .followup-description__text {
      width: 100%;
      font: normal normal normal 1rem Montserrat;
      white-space: pre-wrap;
    }

    .followup-description__file {
      display: flex;
      span {
        font: normal normal normal 1rem Montserrat;
      }

      .file {
        display: flex;
        gap: 1rem;
        button {
          border: none;
          background-color: transparent;
          text-decoration: underline;
          color: ${({ theme }) => theme.colors.blue._600};
          font: normal normal normal 1rem Montserrat;
          cursor: pointer;
          /* margin-left: 1rem; */
          text-align: left;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .followup-info,
    .followup-description {
      .create-info,
      .data-followup {
        font: normal normal bold 0.875rem Montserrat;
      }
      span {
        font: normal normal normal 0.875rem Montserrat;
      }
    }
  }

  @media (max-width: 550px) {
    .followup-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .followup-info__name {
        margin-left: 1.5rem;
      }
    }
  }
`

export const WrapperFollowupList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 22rem;
  margin-right: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
`
export const BtnViewMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  button {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.blue._600};
    transition: 0.2s;
    cursor: pointer;

    span {
      margin-right: 0.25rem;
      border: 2px solid ${({ theme }) => theme.colors.blue._600};
      padding: 0.15rem 0.25rem;
      border-radius: 50%;

      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    &:hover {
      opacity: 0.9;
      font-weight: bold;
    }
  }
`

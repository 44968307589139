/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/quotes */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WrapperFollowup } from './styles'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { TicketDataFollowup } from '../../entities'
import { Fragment, useState } from 'react'
import { MoonLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { getGLPIFileBase64 } from 'services/connector'

type Props = {
  followup: TicketDataFollowup
}

const FollowupItem = ({ followup }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const format = followup.followup.replace(/(Ação|documentação|Psti|Atenciosamente|User|(?=senha)|(?=OBS)|(?=Usuária)|(?=Usuário))/g, '$1\n')
  const followupFormat = format.split(/(Ação(s*)|documentação|Psti|Atenciosamente|(?=User)|(?=senha)|(?=OBS)|(?=Usuária)|(?=Usuário))|\*+/g)
  // const followupFormat = format.split(/(Ação(s*)|documentação|Psti|Atenciosamente|(?=User)|(?=senha)|(?=OBS)|(?=Usuária)|(?=Usuário))|\n\n|\*+/g)

  const handleDownload = async (fileId: string) => {
    setIsLoading(true)
    try {
      const response = await getGLPIFileBase64(fileId)

      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      const downloadLink = document.createElement('a')
      downloadLink.href = response
      downloadLink.download = fileId
      downloadLink.click()
    } catch (error) {
      setIsLoading(false)
      toast.error('Ops 😥, ocorreu um erro ao fazer download')
    }
  }
  return (
    <WrapperFollowup>
      <div className="followup-info">
        <div className="followup-info__date">
          <FontAwesomeIcon icon={faCircle} />
          <span className="create-info">Criado em:</span>
          <span className="data-followup">{followup.date?.toString().split(',')[0]}</span>
        </div>
        {followup.user && (
          <div className="followup-info__name">
            <span className="create-info">Por:</span>
            <span className="data-followup">{followup.user}</span>
          </div>
        )}
      </div>
      <div className="followup-description">
        {followup?.fileId ? (
          <div className="followup-description__file">
            <div className="file">
              <span>Vincular documento:</span>
              <button
                title="Baixar documento"
                onClick={() => {
                  handleDownload(followup?.fileId?.toString() ?? '')
                }}
                disabled={isLoading}
              >
                {followup.followup?.split(':')[1] ?? 'documento'}
              </button>
              {isLoading && <MoonLoader size={15} color={'#123abc'} />}
            </div>
          </div>
        ) : (
          followupFormat.map((followup, index) => (
            <Fragment key={`${followup}+${index}`}>
              <span className="followup-description__text">
                {followup}
              </span>
              <br />
            </Fragment>
          ))
        )}
      </div>
    </WrapperFollowup >
  )
}

export default FollowupItem
